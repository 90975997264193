import * as React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { BASE_URL } from '../../common/common';
import Header from '../../components/Header';
import { styles } from './Style';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import CustomViewer from './CustomViewer.tsx';

const StudyGuide = () => {

    const navigate = useNavigate()
    const studyGuides = useSelector((state) => state.questions.studyGuides)
    const {studyGuideId} = useParams()
    // console.log("studyGuideId===> ", studyGuideId)
    const [isLoading, setIsLoading] = React.useState(true);
    const [studyGuide, setStudyGuide] = React.useState(null);

    const [pdf, setPdf] = React.useState(null);
    React.useEffect(() => {
        // check studyGuideId is present in studyGuides and set it in studyGuide
        if (studyGuides.length !== 0) {
            const studyGuide = studyGuides.find((studyGuide) => studyGuide._id === studyGuideId);
            setStudyGuide(studyGuide);
        }

    }, [studyGuideId, studyGuides])
    // console.log("studyGuide===> ", studyGuide)
    React.useEffect(() => {

        const userVerify = () => {
            const cookies = document.cookie.split('; ');
            const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
            const token = tokenCookie?.split('=')[1];
            // // // // console.log("token==> ", cookies)
            if (token) {
                // navigate("/tests")
            } else {
                navigate("/login")
            }
        }

        const getPDF = async () => {
            try {
                const cookies = document.cookie.split('; ');
                const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
                const token = tokenCookie?.split('=')[1];

                const response = await fetch(`${BASE_URL +  studyGuide?.file }`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                // console.log("response===> ", response);

                if (!response.ok) {
                    throw new Error('Something went wrong');
                }

                const data = await response.json();

                // console.log("data===> ", data);

                if (data.status) {
                    setPdf(data.data[0])
                } else {
                    throw new Error('Something went wrong');
                }
            } catch (error) {
                // // // // console.log("error===> ", error)
            }
        }

        Promise.all([userVerify(), getPDF()])
            .then((res1, res2) => {
            })
            .catch((err) => {
                // // // console.log("error===> ", err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])


    if (isLoading) {
        return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress color='primary' value={50} />
        </div>)
    }

    return (
        <>
            <Header />
            <div className='studyGuides-main-sec'>
                <div className='container'>
                    <h2 style={styles.studyGuidesdHeader}>{studyGuide?.name}</h2>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                        <div
                            style={{
                                height: '90vh',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                            onContextMenu={(e) => e.preventDefault()}
                        >
                            <CustomViewer url={BASE_URL +  studyGuide?.file} />
                        </div>
                    </Worker>
                    
                </div>
            </div>
        </>
    );
};

export default StudyGuide;