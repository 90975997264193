import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAllFlashCards } from '../../redux/flashCardSlice'
import { CircularProgress } from '@mui/material'
import Header from '../../components/Header'

const FlashCards = () => {
    const [falshCardIndex, setFlashCardIndex] = useState(0)
    const [answerState, setAnswerState] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const parmas = useParams()
    const dispatch = useDispatch()
    const flashCards = useSelector((state) => state.flashCards.FlashCards)
    const status = useSelector((state) => state.flashCards.status)
    const subjectId = parmas.subjectId;
    // // // console.log("parmas", subjectId)
    // // // // console.log("flashcards")
    useEffect(() => {
        // // // console.log("loading status", status)
        if (status === "loading") {
            setIsLoading(true)
        } else {
            setIsLoading(false)
        }
    }, [status])
    // // // console.log("loading status", status)
    useEffect(() => {
        dispatch(getAllFlashCards({ subjectId }))
    }, [])
    // // // console.log("Flash cards === > ", flashCards)
    const handleNextClick = () => {
        setFlashCardIndex(falshCardIndex + 1)
        setAnswerState(false);
    }
    const handlePreviousClick = () => {
        setFlashCardIndex(falshCardIndex - 1)
        setAnswerState(false);
    }
    return (
        <>
            <Header questionsPage={false} />
            {isLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <CircularProgress value={25} />
            </div> :

                <div className='flashcards-pages-main-sec'>
                    <div className='container'>
                        <div className='flashcards-inr-sec'>
                            <div className='flashcards-heading-sec'>
                                <h2>Subject: {flashCards[falshCardIndex]?.subjectName}</h2>
                            </div>
                            <div className='row'>

                                <div className='col-lg-8 col-md-10 col-sm-12'>

                                    <div className='flashcards__inrcol-details-main'>
                                        {!isLoading && flashCards[falshCardIndex] ? (
                                            <>
                                                <div className='flashcards__inrcol-details'>
                                                    <h3 dangerouslySetInnerHTML={{ __html: flashCards[falshCardIndex].question }} ></h3>
                                                    <div className='click-wise-btn-flashcards'>
                                                        <button onClick={() => setAnswerState(!answerState)} className='flip__btn-flashcard'>Flip To watch Answer</button>
                                                    </div>
                                                    {/* answers details */}
                                                    <div className={!answerState ? 'answers__flashcards-inr' : 'answers__flashcards-inr attach__answers-with-btnclick'}>
                                                        <div className='answersInnerDiv'>
                                                            <p dangerouslySetInnerHTML={{ __html: flashCards[falshCardIndex].answer }}  ></p>
                                                            {flashCards[falshCardIndex].explanation &&
                                                                <>
                                                                    <p>Explanation:</p>
                                                                    <p dangerouslySetInnerHTML={{ __html: flashCards[falshCardIndex].explanation }}  ></p>
                                                                </>
                                                            }
                                                            <button className='close__btn-click-answers' onClick={() => setAnswerState(!answerState)}><img src={require('../../assets/images/close-icon1.png')} alt='' /></button>
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* prev next btn */}
                                                <div className='last-btn-prev-next-sec'>
                                                    {falshCardIndex !== 0 &&
                                                        <button onClick={handlePreviousClick}>
                                                            <img src={require('../../assets/images/left-arrow-icon1.png')} alt='' /> Prev
                                                        </button>
                                                    }
                                                    {/* {// // // console.log("!falshCardIndex===flashCards.length-1", falshCardIndex, flashCards.length - 1)} */}
                                                    {falshCardIndex !== flashCards.length - 1 &&
                                                        <>

                                                            <button onClick={handleNextClick} >
                                                                Next <img src={require('../../assets/images/right-arrow-icon1.png')} alt='' />
                                                            </button>
                                                        </>
                                                    }

                                                </div>
                                            </>
                                        ) : !isLoading && !flashCards[falshCardIndex] ? (<>

                                            <>
                                                <div className='flashcards__inrcol-details'>
                                                    {/* <h3 dangerouslySetInnerHTML={{ __html: flashCards[falshCardIndex].question }} ></h3> */}

                                                    <h6>No Question available</h6>

                                                </div>
                                                {/* prev next btn */}
                                                <div className='last-btn-prev-next-sec'>


                                                </div>
                                            </>

                                        </>) : (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <CircularProgress value={25} />
                                            </div>
                                        )}
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default FlashCards