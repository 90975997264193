import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API_URL } from '../../common/common';
import { CircularProgress } from '@mui/material';
import "./style.css";
const ForgotPassword = () => {
    const [isResetPasswordRequest, setIsResetPasswordRequest] = useState(false)
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',

    });

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    }
    const handleSendMailClick = (e) => {
        e.preventDefault();
        // // // // console.log('formData ==> ', formData);
        if (!formData.email) {
            Swal.fire({
                title: 'Error!',
                text: "Email Should not be empty",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            Swal.fire({
                title: 'Error!',
                text: "Please enter a valid email address",
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }

        handleSendingMail();
    }
    const handleSendingMail = async () => {
        setIsResetPasswordRequest(true);

        try {
            const response = await fetch(API_URL + 'resetPasswordRequest', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            // localStorage.setItem("studentId", data?.data?.user?._id)
            // // // console.log('data ==> ', data?.data?.user?._id);

            if (!data.status) {
                Swal.fire({
                    title: 'Error!',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }


            // if(data.)

            if (data.status) {
                Swal.fire({
                    title: 'Success!',
                    text: data.message,
                    icon: 'success',
                })

                // navigate("/")
            }
        } catch (error) {
            // // // console.log('error ==> ', error);
        } finally {
            setIsResetPasswordRequest(false);
        }

    }
    return (
        <section className="resetAdminLogin">
            <div className="loginOuterBoxTop">
                <img className="img-fluid logoImg" src={require("../../assets/images/logo.png")} alt="" />
            </div>
            <div className="loginOuterBox form-signin ">
                <div className="loginInnerBox">
                    <form >
                        <div className="formBoxs">
                            <div className="formBox">
                                <input type="text" name="email" id="username" className="form-control"
                                    onChange={handleFormDataChange}
                                    placeholder="Email" required="" />
                            </div>
                        </div>
                        <div className="btnGroup">
                            <button className="btn btn-outline-light btn-lg btn-block form-group-3" style={{ fontWeight: 600 }}
                                onClick={(e) => handleSendMailClick(e)}
                            >
                                {isResetPasswordRequest ? <CircularProgress size={30} /> : "Forgot Password"}
                            </button>

                        </div>
                        {/* <div className="formBox" style={{ color: "white" }}>
                          new here? <span onClick={() => navigate("/register")} style={{ cursor: "pointer" }}>
                              Register
                          </span>
                      </div>
                      <div className="formBox" style={{ color: "white" }}>
                          Forgot Password? <span onClick={() => navigate("/forgotPassword")} style={{ cursor: "pointer" }}>
                              Reset Password
                          </span>
                      </div> */}
                    </form>
                </div>
            </div>
        </section>
    )
}

export default ForgotPassword