import { CircularProgress } from '@mui/material'
import React, { Fragment, useEffect } from 'react'
import Header from '../../components/Header'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getTests, setCurrentQuestionIndex, setQuestionsToEmpty } from '../../redux/questionSlice'
import { getAllSubjects, setFlashCardsEmpty } from '../../redux/flashCardSlice'

const TestFlashCards = () => {
    const dispatch = useDispatch()
    // const tests = useSelector((state) => state.questions.tests)
    const subjects = useSelector((state) => state.flashCards.subjects)
    const navigate = useNavigate()
    useEffect(() => {
        // dispatch(getTests())
        dispatch(setFlashCardsEmpty())
        dispatch(getAllSubjects())

    }, [])
    // // // console.log(subjects)
    const userVerify = () => {
        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie?.split('=')[1];
        // // // console.log("token==> ", cookies)
        if (token) {
            // navigate("/tests")
        } else {
            navigate("/login")
        }
    }

    useEffect(() => {
        userVerify()
    }, [])
    const fetchQuestionByTest = (item) => {
        // // // console.log(item)
        // dispatch(getAllQuestions({testId:item._id})).then((e)=>{
        //     // // // console.log("",e)
        // })
        navigate(`/flashcards/${item._id}`)
    }
    return (
        <>
            <Header />
            <div className='test-btn-all-main-sec'>
                <div className='container'>
                    <div className='testing-btn-inr'>
                        <div className='heading-one-cmntest-que'>
                            <h2>Flash Cards (Subjects Wise)</h2>
                        </div>
                        <div className='row'>
                            {subjects.length !== 0 ? subjects.map((item, i) => (
                                <Fragment key={i}>
                                    <div className='col-lg-4 col-md-6 col-sm-12'>
                                        <button onClick={() => fetchQuestionByTest(item)}>
                                            {/* <p>{item.name}</p> */}
                                            {item.name}
                                        </button>
                                    </div>
                                </Fragment>
                            )) : (
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <CircularProgress color='secondary' value={25} />
                                    </div>

                                </>
                            )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TestFlashCards