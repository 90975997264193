import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Questions from './pages/Questions/Questions';
import Home from './pages/Home/Home';
import Login from './pages/login/Login'
import Tests from './pages/Tests/Tests';
import QuestionsDemo from './components/QuestionsDemo';
import TestDemo from './components/TestDemo';
import './bootstrap.css';
import Header from './components/Header';
import TestSubmitted from './pages/TestSubmitted/TestSubmitted';
// import flashCards from './components/flashCards';
import FlashCards from './pages/FlashCards/FlashCards';
import TestFlashCards from './pages/Subjects/Subjects';
import Dashboard from './pages/Dashboard/Dashboard';
import Register from './pages/Register/Register';
import VerifyEmail from './pages/VerifyEmail/VerifyEmail';
import Profile from './pages/Profile/Profile';
import ForgotPassword from './pages/Forgot Password/ForgotPassword';
import ResetPassword from './pages/ResePassword/ResetPassword';
import StudyGuide from './pages/StudeGuides/StudyGuides';
import AboutUs from './pages/AboutUs/AboutUs';
import AllStudyGuides from './pages/AllStudyGuides/AllStudyGuides';
import Reference from './pages/Reference/Reference';
import ReferencePdf from './pages/ReferecePdf/ReferencePdf';

function App() {

  return (
    <BrowserRouter basename='/'>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/questions/:testId" element={<Questions />} />
        {/* <Route path="/header" element={<Header />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/tests" element={<Tests />} />
        <Route path="/subjects" element={<TestFlashCards />} />
        <Route path="/flashcards/:subjectId" element={<FlashCards />} />
        <Route path="/submitTest/:testId" element={<TestSubmitted />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verifyEmail" element={<VerifyEmail />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        {/* <Route path="/flashCards" element={<TestSubmitted />} />*/}
        <Route path='/allStudyGuides' element={<AllStudyGuides />} />
        <Route path="/studyGuides/:studyGuideId" element={<StudyGuide />} />
        <Route path="/references/:testId" element={<Reference />} />
        <Route path="/referencePdf" element={<ReferencePdf />} />
        <Route path="/aboutUs" element={<AboutUs />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
