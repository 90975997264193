import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API_URL } from '../../common/common';
import "./style.css"

const Register = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        name: '',
        confirmPassword: ''
    });


    /* After register API Call (URL: /register, Method: POST, Body: {email, password, name}), redirect to /login if verifyEmail is false, else (verifyEmail: true) make API call (URL: /verifyEmailRequest, Method: POST, Body: {email}).  
   Verify Email Request success response will be like this:
    {
    "code": 200,
    "status": true,
    "message": "Email sent successfully!"
    }
    On success, show a message like "Email sent successfully! Please verify your email to login." and redirect to /login else show a message like "Email cannot be sent right now. Please try again later." and stay on the same page. 
    Next instruction is in /verifyEmail route.
    */
    const handleFormDataChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
        // // // console.log(formData)
    }
    const handleRegisterClick = (e) => {
        e.preventDefault();
        // // // // console.log('formData ==> ', formData);
        if (!formData.email) {
            Swal.fire({
                title: 'Error!',
                text: "Email Should not be empty",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            Swal.fire({
                title: 'Error!',
                text: "Please enter a valid email address",
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }

        if (!formData.password) {
            Swal.fire({
                title: 'Error!',
                text: "Password Should not be empty",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return
        }
        if (formData.password !== formData.confirmPassword) {
            Swal.fire({
                title: 'Error!',
                text: "Confirm Password Should be same",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return
        }

        handleRegister()
    }
    const handleRegister = async () => {
        const response = await fetch(API_URL + 'register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        const data = await response.json();
        // localStorage.setItem("studentId", data?.data?.user?._id)
        // // // console.log('data ==> ', data);



        if (!data.isEmailVerified) {
            const emailVerificationResponse = await fetch(API_URL + 'verifyEmailRequest', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: formData.email.toLowerCase().trim() }),
            });
            const emailVerificationResponseData = await emailVerificationResponse.json();
            // localStorage.setItem("studentId", data?.data?.user?._id)
            // // // console.log('emailVerificationResponseData ==> ', emailVerificationResponseData);
            if (emailVerificationResponseData.status) {

                Swal.fire({
                    title: 'Success!',
                    text: emailVerificationResponseData.message + "! Please verify your email to login.",
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
                navigate("/login")

            } else {

                Swal.fire({
                    title: 'Error!',
                    text: emailVerificationResponseData.message,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }

        }
    }

    return (
        <section className="adminLogin">
            <div className="loginOuterBoxTop">
                <img className="img-fluid logoImg" src={require("../../assets/images/logo.png")} alt="" />
                {/* <h2 className="h3 fw-normal" style={{ textAlign: 'center', color: 'white' }}>Elite Minds</h2> */}
                <h1 className="h3 mb-3 asvabHeading" style={{ textAlign: 'center', color: 'white' }}>ASVAB APP</h1>

                <div className="formBoxTextBtn" style={{ color: "white", marginTop: '10px', textAlign: 'center' }}>
                    Already registered? <span className='btnText' onClick={() => navigate("/login")} style={{ cursor: "pointer", textDecoration: 'underline' }}>
                        Login
                    </span>
                </div>

                <div className="formBox" style={{ color: "white", marginTop: '10px', textAlign: 'center' }}>
                    OR
                </div>
                <h1 className="h3 LGin" style={{ textAlign: 'center', color: 'white' }}>REGISTER</h1>
            </div>
            <div className="loginOuterBox form-signin ">
                <div className="loginInnerBox">

                    <form>
                        <div className="formBoxs">
                            <div className="formBox">
                                <input type="text" name="name" id="name" className="form-control"
                                    onChange={handleFormDataChange}
                                    placeholder="Name" required="" />
                            </div>
                            <div className="formBox">
                                <input type="text" name="email" id="email" className="form-control"
                                    onChange={handleFormDataChange}
                                    placeholder="Email" required="" />
                            </div>
                            <div className="formBox">
                                <input type="password" name="password" id="password" className="form-control"
                                    onChange={handleFormDataChange}
                                    placeholder="Password" required="" />
                            </div>
                            <div className="formBox">
                                <input type="password" name="confirmPassword" id="confirmPassword" className="form-control"
                                    onChange={handleFormDataChange}
                                    placeholder="Confirm Password" required="" />
                            </div>
                        </div>
                        <div className="btnGroup">
                            <button className="btn btn-outline-light btn-lg btn-block form-group-3" style={{ fontWeight: 600 }}
                                onClick={(e) => handleRegisterClick(e)}
                            >
                                Register
                            </button>

                        </div>

                    </form>
                </div>
            </div>
        </section>
    )
}

export default Register