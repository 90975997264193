import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { CircularProgress } from '@mui/material';

const Home = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const userVerify = new Promise((resolve, reject) => {
    const cookies = document.cookie.split('; ');
    const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
    const token = tokenCookie?.split('=')[1];
    // // // console.log("token==> ", cookies)
    if (token) {
      resolve(token)
    } else {
      resolve(null)
    }
  })

      
  useEffect(() => {
    userVerify.then((token) => {
      if (token) {
        setIsLoading(false)
      } else {
        navigate("/login")
      }
    })
  }, [])
  // if (isLoading) {
  //   return null
  // }

  return (
    <div>
      {isLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress value={25} />
      </div> :
        <>
          <Header />

          <div className='cram-course-main-sec'>
            <div className='container'>
              <div className='row'>
                {/* <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='cram-course-inr-details-sec'>
                <div className='icon-sec-inr-cram-course'>
                  <img src={require('../../assets/images/course-icon1.png')} alt=''/>
                </div>
                <h3>Cram Course</h3>
                <p>Learn everything you will need to</p>
                <div className='btn-sec-last-cram-course'>
                  <button className='all-cmn-btn-cram-courses'>Study Summer</button>
                </div>
              </div>
            </div> */}
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div className='cram-course-inr-details-sec'>
                    <div className='icon-sec-inr-cram-course'>
                      <img src={require('../../assets/images/test-icon1.png')} alt='' />
                    </div>
                    <h3>Practice Tests</h3>
                    <p>Learn everything you will need to</p>
                    <div className='btn-sec-last-cram-course'>
                      <button className='all-cmn-btn-cram-courses'
                        onClick={() => navigate("/tests")}
                      >Start Practice Tests</button>
                    </div>
                  </div>
                </div>
                {/* <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='cram-course-inr-details-sec'>
                <div className='icon-sec-inr-cram-course'>
                  <img src={require('../../assets/images/lesson-icon1.png')} alt=''/>
                </div>
                <h3>Practice Lessons</h3>
                <p>Learn everything you will need to</p>
                <div className='btn-sec-last-cram-course'>
                  <button className='all-cmn-btn-cram-courses'>Study Summer</button>
                </div>
              </div>
            </div> */}
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div className='cram-course-inr-details-sec'>
                    <div className='icon-sec-inr-cram-course'>
                      <img src={require('../../assets/images/flash-card-icon1.png')} alt='' />
                    </div>
                    <h3>Flashcards</h3>
                    <p>Learn everything you will need to</p>
                    <div className='btn-sec-last-cram-course'>
                      <button className='all-cmn-btn-cram-courses'
                        onClick={() => navigate("/subjects")}
                      >View Flashcards
                      </button>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div className='cram-course-inr-details-sec'>
                    <div className='icon-sec-inr-cram-course'>
                      <img src={require('../../assets/images/guides-icon1.png')} alt='' />
                    </div>
                    <h3>Study Guides</h3>
                    <p>Learn everything you will need to</p>
                    <div className='btn-sec-last-cram-course'>
                      <button className='all-cmn-btn-cram-courses'
                        onClick={() => navigate("/allStudyGuides")}
                      >Read Study Guides</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default Home