import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Header from '../../components/Header'
import { CircularProgress } from '@mui/material'
import { Worker } from '@react-pdf-viewer/core'
import CustomViewer from '../StudeGuides/CustomViewer.tsx'
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { API_URL, BASE_URL } from '../../common/common'

const Reference = () => {
    const dispatch = useDispatch()
    const studyGuides = useSelector((state) => state.questions.studyGuides)
    const tests = useSelector((state) => state.questions.tests)
    const [reference, setReference] = useState()
    const { testId } = useParams()
    // console.log("testsId===> ", testId)
    // console.log("reference===> ", reference)
    const [test, setTest] = useState('')
    const navigate = useNavigate()
    //using testId filter the test name from tests
    const getTestName = () => {
        const test = tests.filter((item) => item._id === testId)[0]
        // console.log("test===> ", test)
        setTest(test)
    }

    useEffect(() => {
        getTestName()
    }, [testId, tests])
    // // // console.log(tests)

    const getReferenceByTestId = () => {
        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie?.split('=')[1];
        fetch(`${API_URL}getReferencesByTestId/${testId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }
        )
            .then((res) => res.json())
            .then((data) => {
                // console.log("data===> ", data)
                setReference(data.data)
            })

    }

    const userVerify = () => {
        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie?.split('=')[1];
        // // // console.log("token==> ", cookies)
        if (token) {
            // navigate("/tests")
        } else {
            navigate("/login")
        }
    }

    useEffect(() => {
        userVerify()
        getReferenceByTestId()
    }, [])
    const fetchRef = (item) => {
        // // // console.log(item)
        // dispatch(getAllQuestions({testId:item._id})).then((e)=>{
        //     // // // console.log("",e)
        // })
        // console.log("Ref item===> ", item)
        navigate(`/referencePdf`, { state: { item } })
    }
    
    const truncateString = (str, num) => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + '...';
    };

    return (
        <>
            <Header />
            <div className='test-btn-all-main-sec'>
                <div className='container'>
                    <div className='testing-btn-inr'>
                        <div className='heading-one-cmntest-que'>
                            <h2>References</h2>
                        </div>
                        <div className='row'>
                            {reference && reference.length !== 0 ? reference.map((item, i) => (
                                <Fragment key={i}  >
                                    <div className='col-lg-4 col-md-6 col-sm-12' >
                                        <button onClick={() => fetchRef(item)}>
                                            {/* <p>{item.name}</p> */}
                                            {truncateString(item.name, 20)}
                                        </button>
                                    </div>
                                </Fragment>
                            )) : (
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <CircularProgress color='secondary' value={25} />
                                    </div>

                                </>
                            )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Reference