import React from 'react'
import { Worker } from '@react-pdf-viewer/core'
import CustomViewer from '../StudeGuides/CustomViewer.tsx'
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Header from '../../components/Header.js';
import { BASE_URL } from '../../common/common.js';
import { useLocation } from 'react-router-dom';

const ReferencePdf = () => {
    const location = useLocation();
    // const { id } = useParams();
    const { item } = location.state ;
    // console.log("item===> ", item)
  return (
      <>
          <Header />
          <div className='test-btn-all-main-sec'>
              <div className='container'>
                  <div className='heading-one-cmntest-que'>
                      <h2>{item.name} </h2>
                  </div>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                      <div
                          style={{
                              height: '90vh',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                          }}
                      >
                          <CustomViewer download={true} url={BASE_URL + item.file} />
                      </div>
                  </Worker>
              </div>
          </div>
      </>
  )
}

export default ReferencePdf