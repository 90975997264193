import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllQuestions, getTests, setCurrentQuestionIndex, setQuestionsToEmpty } from '../../redux/questionSlice'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import { CircularProgress } from '@mui/material'


const Tests = () => {
    const dispatch = useDispatch()
    const tests = useSelector((state) => state.questions.tests)
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(getTests())
        dispatch(setQuestionsToEmpty())
        dispatch(setCurrentQuestionIndex(0))
    }, [])
    // // // console.log(tests)
    const userVerify = () => {
        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie?.split('=')[1];
        // // // console.log("token==> ", cookies)
        if (token) {
            // navigate("/tests")
        } else {
            navigate("/login")
        }
    }
    
    

    useEffect(() => {
        userVerify()
    }, [])
    const fetchQuestionByTest = (item) => {
        // // // console.log(item)
        // dispatch(getAllQuestions({testId:item._id})).then((e)=>{
        //     // // // console.log("",e)
        // })
        navigate(`/questions/${item._id}`)
    }

    return (
        <>
            <Header />
            <div className='test-btn-all-main-sec'>
                <div className='container'>
                    <div className='testing-btn-inr'>
                        <div className='heading-one-cmntest-que'>
                            <h2>Tests</h2>
                        </div>
                        <div className='row'>
                            {tests.length !== 0 ? tests.map((item, i) => (
                                <Fragment key={i}  >
                                    <div className='col-lg-4 col-md-6 col-sm-12' >
                                        <button onClick={() => fetchQuestionByTest(item)}>
                                            {/* <p>{item.name}</p> */}
                                            {item.name}
                                        </button>
                                    </div>
                                </Fragment>
                            )) : (
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <CircularProgress color='secondary' value={25} />
                                    </div>

                                </>
                            )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Tests