import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useDispatch, useSelector } from 'react-redux';
import { green } from '@mui/material/colors';
import { questionOfCurrentIndexUpdate, setCurrentQuestionIndex } from '../redux/questionSlice';
import { style } from './QuestionBarStyle';


export default function TemporaryDrawer() {
  const [open, setOpen] = React.useState(true);
  const questions = useSelector((state) => state.questions.questions)
  const currentQuestionIndex = useSelector((state) => state.questions.currentQuestionIndex)
  // const questionOfIndex = useSelector((state) => state.questions.questionOfIndex)
  const dispatch = useDispatch();
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // // // // console.log("Question Bar questions,",questions)
  const questionMenuClick = (i) => {
    // // // console.log("question Menu C")
    dispatch(setCurrentQuestionIndex(i))
    // questionOfCurrentIndexUpdate(i)
  }


  // const DrawerList = (
  //   <Box className='sidebar__box__listing' sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(!open)} >
  //     <Box className='heading__sidebarbox'>
  //       <h3>Questions: </h3>
  //     </Box>
  //     <List
  //     sx={style.listingSidebar}
  //     >
  //       {questions && questions.map((question, i) => (
  //         <ListItem key={i} disablePadding 
  //         sx={style.listingItemsSidebars}
  //         >
  //           <ListItemButton  
  //             onClick={() => questionMenuClick(i)}
  //             sx={{ 
  //               background: question.givenAnswer ? '#fcff6b' : '#fff', 
  //               border: currentQuestionIndex === i ? '1px solid #fff' : 'none', 
  //               boxShadow: currentQuestionIndex === i ? 'inset 0 200px #00ab45' : '0 0 0px #ebffe5', 
  //               color: currentQuestionIndex === i ? '#fff' : '#181818', 
  //               borderRadius: '30px',
  //             }}
  //           >
  //             <ListItemText sx={style.liListingDivSidebar} primary={i + 1} />
  //           </ListItemButton>
  //         </ListItem>
  //       ))}
  //     </List>
  //     <Divider />
  //   </Box>
  // );

  // return (
  //   <div className='custom-sidebar-btn-sec-main'>
  //     <Button className='btn-toggle-sidebar' onClick={toggleDrawer(true)}><img src={require('../assets/images/sidebar-menu.png')} alt='' /></Button>
  //     {window.location.pathname != '/questions' &&
  //       <Drawer open={open} onClose={toggleDrawer(true)}>
  //         {DrawerList}
  //       </Drawer>
  //     }
  //   </div>
  // );
}




const QuestionsBar = () => {
  return (
    <div>QuestionsBar</div>
  )
}

// export default QuestionsBar