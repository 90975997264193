// export const BASE_URL = 'http://66.29.152.165/elitemindsapp-backend/'; // Live
// export const BASE_URL = 'http://localhost:4005/asvab/backend/'; // Local
export const BASE_URL = 'https://elitemindsapp.com/backend/'; // Live
// export const HOME_URL = 'http://localhost:3000/asvab-admin/'; // Local
export const HOME_URL = 'https://elitemindsapp.com/admin/'; // Live
// export const API_URL = 'http://localhost:4005/asvab/api/v1/'; // Local
// export const API_URL = 'http://194.163.131.163:4005/asvab/api/v1/'; // Live
// export const API_URL = 'http://66.29.152.165:4005/asvab/api/v1/'; // Live
export const API_URL = 'https://elitemindsapp.com/backend/api/v1/'; // Live


