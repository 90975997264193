import React, { useEffect, useState } from 'react'
import { API_URL } from '../../common/common';
import Header from '../../components/Header';
import { useNavigate, useParams } from 'react-router-dom';

const TestSubmitted = () => {
  const navigate = useNavigate()
  const [marksheet, SetMarkSheet] = useState()
  const params = useParams()
  const testId = params.testId
  // // // console.log("params",params)
  const userVerify = () => {
    const cookies = document.cookie.split('; ');
    const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
    const token = tokenCookie?.split('=')[1];
    // // // console.log("token==> ", cookies)
    if (token) {
      // navigate("/tests")
    } else {
      navigate("/login")
    }
  }

  useEffect(() => {
    userVerify()
  }, [])
  const getMarksheet = async () => {
    const cookies = document.cookie.split('; ');
    const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
    const token = tokenCookie?.split('=')[1];
    const studentId = localStorage.getItem("studentId")
    const response = await fetch(API_URL + 'getScores/', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "testId": testId,
        "studentId": studentId
      })
    });

    if (!response.ok) {
      throw new Error('Server error!');
    }
    const data = await response.json();
    // // // console.log("data===> ", data)
    SetMarkSheet(data.data)
  }
  useEffect(() => {
    getMarksheet()
  }, [])
  return (<>
    {/* <div>Result</div> */}
    {marksheet ? (
      <> <Header />
        <div className='student-tests-submitted-main-sec'>
          <div className='container'>
            <div className='test-submitted-inr-main-sec'>
              <h3>Your Score : {marksheet.score}</h3>
              <h4>Percentage : {marksheet.scoreInPercentage}</h4>
            </div>
            <div className='questions-all-submitted-result-inr'>
              <div className='row'>
                {marksheet?.questionsWithOptions?.map((item, i) => (
                  <div className='col-lg-3 col-md-4 col-sm-6' key={i}>
                    <div className='col-gaps-submitted-results'>
                      Question {i + 1}. <h5 dangerouslySetInnerHTML={{ __html: item.questionName }}></h5>
                      {item.options.map((option) => (
                        <p dangerouslySetInnerHTML={{ __html: option }}></p>
                      ))}
                      Your answer:
                      <p dangerouslySetInnerHTML={{ __html: item.givenAnswer }}></p>
                      correctAnswer :
                      <p dangerouslySetInnerHTML={{ __html: item.correctAnswer }}></p>
                      Explanation:
                      <p dangerouslySetInnerHTML={{ __html: item.explanation }}></p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <><h1>Loading......</h1></>
    )}
  </>
  )
}

export default TestSubmitted