import * as React from 'react';
import Header from '../../components/Header';
import { styles } from './Style';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Link } from '@mui/material';

const AboutUs = () => {

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const userVerify = () => {
            const cookies = document.cookie.split('; ');
            const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
            const token = tokenCookie?.split('=')[1];
            // // // // console.log("token==> ", cookies)
            if (token) {
                // navigate("/tests")
            } else {
                navigate("/login")
            }
        }

        Promise.all([userVerify()])
            .then((res1) => {
            })
            .catch((err) => {
                // // // console.log("error===> ", err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [navigate])


    if (isLoading) {
        return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress color='primary' value={50} />
        </div>)
    }

    return (
        <>
            <Header />
            <div className='aboutUs-main-sec'>
                <div className='container'>
                    <div className='about__us-inr-sec'>
                        <h2 style={styles.aboutUsHeader}>About Us</h2>
                        <div style={styles.AboutUsDetailsInr}>
                            <h3 style={styles.AboutDetailsInrH3}>Welcome to Elite Minds Publishing</h3>
                            <p style={styles.AllParaAbtPagesFirst}>Where knowledge and excellence converge to create exceptional educational resources. Founded with a passion for fostering learning and achievement, Elite Minds Publishing is dedicated to delivering top-tier study guides and materials that empower individuals on their educational journeys. Our commitment to quality, precision, and innovation drives us to craft comprehensive and user-friendly resources that facilitate success in various fields. With a team of experts and a focus on providing valuable insights, we aim to inspire and equip learners, whether they are preparing for exams, embarking on new careers, or seeking personal growth. At Elite Minds Publishing, we believe that knowledge is the cornerstone of progress, and we are here to help you reach new heights in your educational pursuits.</p>
                        </div>
                        <div className='steps-all-aboutpage'>
                            <h4 style={styles.aboutUsDetailsinrH4}>Share Your Experience with Us!</h4>
                            <div className='row'>
                                <div className='col-lg-7 col-md-10 col-sm-12 mx-auto'>
                                    <div style={styles.StepsInrAbtpage}>
                                        <p style={styles.AllParaAbtPages}><strong style={styles.StrongsAbtPara}>step 1:</strong>
                                            If you haven't done so already, we invite you to leave your review of our ASVAB Study Guide on Amazon. Your feedback is not only incredibly motivating for us, but it also helps others make informed decisions. Feel free to mention what you enjoyed most about the guide, and consider including a photo or short video to enhance your review.</p>
                                    </div>
                                </div>
                                <Box
                                    sx={styles.submitButtonOuterBox}
                                >
                                    <Box
                                        sx={styles.submitButtonInnerBox}
                                    >
                                        <Link href='https://www.amazon.com/review/create-review/?ie=UTF8&channel=glance-detail&asin=B0CHLCF7CW' target='_blank' rel='noreferrer'>
                                            <Button
                                                variant='contained'
                                                sx={styles.submitButton}
                                            >Submit Your Review Here!
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>

                                <div className='col-lg-7 col-md-10 col-sm-12 mx-auto'>
                                    <div style={styles.StepsInrAbtpage}>
                                        <p style={styles.AllParaAbtPages}><strong style={styles.StrongsAbtPara}>step 2:</strong>
                                            Once your review is published on Amazon, please send a screenshot of it to <a className='AnchorTagsStyle' href='mailto:info@elitemindspublishing.com' target='_blank'
                                                rel='noreferrer'
                                            >info@elitemindspublishing.com</a>. As a token of our gratitude, we will provide you with an additional 100+ hours of video lessons. </p>
                                    </div>
                                </div>
                            </div>
                            <div className='contact-information-aboutpages'>
                                <h4 style={styles.aboutUsDetailsinrH4}>Contact Information:</h4>
                                <div className='row'>
                                    <div className='col-lg-7 col-md-10 col-sm-12 mx-auto'>
                                        <div style={styles.StepsInrAbtpage} className='text-center'>
                                            <p style={styles.AllParaAbtPages}>If you have any questions, need further assistance, or wish to share additional feedback, please don't hesitate to contact us. We are here to help and support you in your educational journey.</p>
                                            <p style={styles.AllParaAbtPages}>Contact us at: <a href='mailto:info@elitemindspublishing.com' target='_blank' rel='noreferrer' className='AnchorTagsStyle'>info@elitemindspublishing.com</a></p>
                                            <p style={styles.AllParaAbtPages}>Website: <a href='https://elitemindspublishing.com/' target='_blank' rel='noreferrer' className='AnchorTagsStyle'>www.elitemindspublishing.com</a></p>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutUs;