import React, { useEffect, useState } from 'react'
import { API_URL } from '../../common/common';
import Header from '../../components/Header';
import { Link, json, useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, LinearProgress, Paper, Tab, Tabs, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getAllQuestionsByStudent, retakeTest, setCurrentQuestionIndex, setQuestionsToEmpty } from '../../redux/questionSlice';

function ProgressBarWithLabel({ value }) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const Dashboard = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true);

  const [studentProgress, setStudentProgress] = useState([])
  const [studentSubjectWiseProgress, setStudentSubjectWiseProgress] = useState([])

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const navigate = useNavigate()
  // // // // console.log("isLoading ", isLoading)
  const userVerify = () => {
    const cookies = document.cookie.split('; ');
    const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
    const token = tokenCookie?.split('=')[1];
    // // // console.log("token==> ", cookies)
    if (token) {
      // navigate("/tests")
    } else {
      navigate("/login")
    }
  }


  const getStudentSubjectProgress = async () => {
    const cookies = document.cookie.split('; ');
    const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
    const token = tokenCookie?.split('=')[1];

    let studentId = localStorage.getItem("studentId")

    const response = await fetch(API_URL + 'getSubjectWiseProgress/' + studentId, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Server error!');
    }
    const data = await response.json();
    // console.log("setStudentSubjectWiseProgress===> ", data)

    setStudentSubjectWiseProgress(data.data.subjectScores)

    setStudentProgress(data.data.testProgress)
  }


  useEffect(() => {
    Promise.all([userVerify(), getStudentSubjectProgress()])
      .then((res1, res2) => {
      })
      .catch((err) => {
        // // // console.log("error===> ", err)
      })
      .finally(() => {
        setIsLoading(false)
      })

  }, [])

  // // // console.log("studentProgress===> ", studentProgress);

  const handleRetakeTest = async (test) => {
    // // // console.log("Reatake Test test ", test)
    const cookies = document.cookie.split('; ');
    const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
    const token = tokenCookie.split('=')[1];

    const studentId = localStorage.getItem("studentId")
    // let response = await fetch(API_URL + 'retakeTest/', {
    //   method: 'POST',
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     "testId": test.testId,
    //     studentId
    //   })
    // });
    // const res = await response.json()
    // // // // console.log("res", res)
    // if (res.status) {
    //   dispatch(getAllQuestionsByStudent({ testId: test.testId }))
    //   navigate(`/questions/${test.testId}`)
    // }
    const testId = test.testId
    dispatch(retakeTest({ testId })).then((e) => {
      // // // console.log("retakeTheTest dispatch response ", e)
      dispatch(setQuestionsToEmpty())
      if (e.type === "test/retakeTest/fulfilled") {
        dispatch(getAllQuestionsByStudent({ testId })).then((e) => {
          // // // console.log("getAllQuestionsByStudent,==> ", e)
          setIsLoading(false)
        })
        dispatch(setCurrentQuestionIndex(0))
        navigate(`/questions/${testId}`)
      }
    })
  }

  return (
    <>

      <Header />
      <div className='dashboard-main-sec' style={styles.dashboardContainer}>
        <div className='container'>
          <div className='dashboard-inr-sec'>
            <h2 style={styles.dashboardHeader}>Dashboard</h2>
            {isLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
              <CircularProgress color='secondary' value={25} />
            </div> :
              <>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="dashboard tabs"
                  variant="fullWidth"
                  indicatorColor="secondary"
                  sx={{
                    marginBottom: 2,
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    borderRadius: 2,
                    fontWeight: '600'
                  }}
                >
                  <Tab label="Test Wise Progress"
                    sx={{
                      fontWeight: '700',
                      backgroundColor: value === 0 ? '#3aefed' : '#a8fffe',
                      color: value === 0 ? '#0d25a3 !important' : '#3b50bf !important',
                    }}
                  />
                  <Tab label="Subject Wise Progress"
                    sx={{
                      fontWeight: '700',
                      backgroundColor: value === 1 ? '#3aefed' : '#a8fffe',
                      color: value === 1 ? '#0d25a3 !important' : '#3b50bf !important',
                    }}
                  />
                </Tabs>
                {value === 0 && studentProgress.length === 0 &&
                  <>
                    <Typography
                      variant="h6"
                      gutterBottom
                      color={'#fff'}
                      align='center'
                    >
                      No test progress found
                    </Typography>

                    <Typography
                      variant="h6"
                      gutterBottom
                      color={'#fff'}
                      align='center'
                    >
                      Please attempt some tests to see your progress
                    </Typography>

                    <Link to='/tests' style={{ color: '#f8b100', fontSize: '1.3rem', fontWeight: '600', textDecoration: 'none', display: 'block', textAlign: 'center', marginTop: 20 }}>
                      Attempt Tests
                    </Link>

                  </>
                }

                {value === 0 && studentProgress.map((test, index) => (

                  <Paper key={index} sx={{ width: '100%', marginBottom: 2, padding: 2, borderRadius: 2, backgroundColor: '#f5f5f5' }}>
                    {/* {// // // console.log("test ",test)} */}
                    <Link to={`/questions/${test.testId}`} style={styles.testLink}>
                      {test.testName}
                    </Link>
                    <Typography variant="body1" gutterBottom>
                      Attempted Questions: {test.attemptedQuestions} / {test.totalQuestions}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Percentage of Attempted Questions: {test.attemptedQuestionsPercentage}%
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Percentage of Correct Answers: {test.score} ({test.scoreInPercentage}%)
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Test Completion Status: {test.completed ? 'Completed' : 'Not Completed'}
                    </Typography>
                    <ProgressBarWithLabel value={Number(test.attemptedQuestionsPercentage)} />
                    <Button variant="contained"
                      onClick={() => handleRetakeTest(test)} sx={styles.retakeBtnText}
                    // onClick={handleRetakeTest(test)}
                    >
                      Retake Test
                    </Button>
                  </Paper>
                ))}
              </>
            }
          </div>
          <div className='dashboard-inr-sec'>
            {/* <h2 style={styles.dashboardHeader}>Subject Wise progress</h2> */}
            {isLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
              <CircularProgress color='secondary' value={25} />
            </div> :
              <>
                {value === 1 && studentSubjectWiseProgress.length === 0 &&
                  <>
                    <Typography
                      variant="h6"
                      gutterBottom
                      color={'#fff'}
                      align='center'
                    >
                      No subject progress found
                    </Typography>

                    <Typography
                      variant="h6"
                      gutterBottom
                      color={'#fff'}
                      align='center'
                    >
                      Please attempt some tests to see your progress
                    </Typography>

                    <Link to='/tests' style={{ color: '#f8b100', fontSize: '1.3rem', fontWeight: '600', textDecoration: 'none', display: 'block', textAlign: 'center', marginTop: 20 }}>
                      Attempt Tests
                    </Link>

                  </>
                }

                {value === 1 && studentSubjectWiseProgress.map((test, index) => (

                  <Paper key={index} sx={{ width: '100%', marginBottom: 2, padding: 2, borderRadius: 2, backgroundColor: '#f5f5f5' }}>
                    {/* {// // // console.log("test ",test)} */}
                    <Box style={styles.testLink}>
                      {test.subjectName}
                    </Box>
                    <Typography variant="body1" gutterBottom>
                      Attempted Questions: {test.attemptedQuestions} / {test.totalQuestionsOfSubject}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Percentage of Attempted Questions: {(test.attemptedQuestionsPercentage)}%
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Percentage of Correct Answers: {test.score} ({(test.scoreInPercentage)}%)
                    </Typography>
                    {/* <Typography variant="body1" gutterBottom>
                      Test Completion Status: {test.completed ? 'Completed' : 'Not Completed'}
                    </Typography> */}
                    <ProgressBarWithLabel value={Number(test.scoreInPercentage)} />
                    {/* <Button variant="contained"
                      onClick={() => handleRetakeTest(test)} sx={styles.retakeBtnText}
                    // onClick={handleRetakeTest(test)}
                    >
                      Retake Test
                    </Button> */}
                  </Paper>
                ))}
              </>
            }
          </div>
        </div>
      </div >


    </>
  )
}

export default Dashboard

const styles = {
  dashboardContainer: {
    padding: '30px 15px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    // boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    minHeight: '90vh',
  },
  dashboardHeader: {
    textAlign: 'center',
    marginBottom: 25,
    color: '#fff',
    textShadow: '1px 2px 4px #000000',
  },
  testLink: {
    color: '#1976d2',
    fontSize: '1.3rem',
    fontWeight: '600',
    textDecoration: 'none',
    marginBottom: '12px',
    display: 'block'
  },
  retakeBtnText: {
    marginTop: '15px',
    background: 'linear-gradient(to right, #1FA2FF 0%, #12D8FA  51%, #1FA2FF  100%)',
    backgroundSize: '200% auto',
    padding: '10px 18px',
    fontSize: '16px',
    color: '#fff'
  }
};

