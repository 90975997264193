import React, { useEffect } from 'react'
import TemporaryDrawer from './QuestionsBar'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { Box, Button, Drawer, IconButton, List, ListItem, Tooltip } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import BackIcon from '@mui/icons-material/ArrowBack';
import { BASE_URL } from '../common/common'
import { getUser } from '../redux/userSlice'

export function Header({ questionsPage }) {

    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const userData = useSelector((state) => state.user.userData);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUser());
    }, [dispatch]);

    // // // console.log("userData ==>", userData);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const navigate = useNavigate()

    const logout = () => {
        // // // // console.log("logout clicked");
        function deleteCookie(name) {
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            navigate("/login")
        }

        // Usage example: Deleting the "asvab-student-token" cookie
        deleteCookie("asvab-student-token");

    }

    return (
        <div className='header-main-sec'>
            <div className='container'>
                <div className='header-inr-sec'>
                    <div className='logo-and-nav'>
                        <IconButton
                            // color="inherit"
                            aria-label="go back"
                            edge="start"
                            onClick={() => {
                                window.history.back();
                            }}
                            sx={{
                                display: {
                                    xs: 'flex', lg: 'none',
                                    color: '#fff',
                                    background: 'linear-gradient(to right, #FF512F 0%, #DD2476  51%, #FF512F  100%);',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '5px',
                                    marginLeft: '3px'
                                }
                            }}
                        >
                            <BackIcon />
                        </IconButton>
                        <div className='logo-sec-header'>
                            <Link to='/'>
                                <img
                                    src={require("../assets/images/logo.png")}
                                    alt='logo' /></Link>
                        </div>
                        <Box className='nav-links'
                            sx={{
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'none',
                                    lg: 'flex',
                                    xl: 'flex',
                                },
                            }
                            }
                        >
                            <NavLink to='/' style={{ marginLeft: '0' }}>Home</NavLink>
                            <NavLink to='/dashboard' style={{ marginLeft: '0' }}>Dashboard</NavLink>
                            <NavLink to='/tests' style={{ marginLeft: '0' }}>Tests</NavLink>
                            <NavLink to='/subjects' style={{ marginLeft: '0' }}>FlashCards</NavLink>
                            <NavLink to='/allStudyGuides' style={{ marginLeft: '0' }}>Study Guides</NavLink>
                            <NavLink to='/aboutUs' style={{ marginLeft: '0' }}>About Us</NavLink>
                        </Box>
                    </div>

                    <div className='right-sec-header-sidebar-flex-main'>
                        <Tooltip title="Profile">
                            <Button
                                // color="inherit"
                                aria-label="Profile"
                                edge="start"
                                onClick={() => navigate("/profile")}
                                sx={{
                                    display: { sm: 'none', xs: 'none', md: 'none', lg: 'block' },
                                }}
                            >
                                <img
                                    src={userData?.image ? BASE_URL + userData?.image : require('../assets/images/default_profile.png')}
                                    alt='profile-icon'
                                    style={{
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '50%'
                                    }}
                                />
                            </Button>
                        </Tooltip>

                        <div className='right-header-logout-sec'>

                            <Button
                                className='logout-btn'
                                onClick={logout}
                                sx={{
                                    display: {
                                        xs: 'none',
                                        lg: 'block'
                                    },
                                    color: '#fff',
                                }}
                            >
                                Logout
                            </Button>
                            <IconButton
                                // color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ display: { xs: 'block', lg: 'none' } }}
                            >
                                <MenuIcon
                                    sx={{ color: '#fff' }}
                                />
                            </IconButton>

                        </div>


                        {questionsPage && <TemporaryDrawer />}

                        <Drawer className='sidebar-responsive-bg-col'
                            variant="temporary"
                            open={drawerOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: { xs: 'block', lg: 'none' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                            }}
                        >
                            <Box onClick={handleDrawerToggle} className='close-btn-sidebar-responsive'><img src={require('../assets/images/close-icon1.png')} alt='close' /></Box>
                            <List sx={{
                                paddingTop: '0'
                            }}>
                                <ListItem component={NavLink} to="/"
                                    onClick={handleDrawerToggle}
                                >Home</ListItem>
                                <ListItem component={NavLink} to="/dashboard"
                                    onClick={handleDrawerToggle}
                                >Dashboard</ListItem>
                                <ListItem component={NavLink} to="/tests"
                                    onClick={handleDrawerToggle}
                                >Tests</ListItem>
                                <ListItem component={NavLink} to="/subjects"
                                    onClick={handleDrawerToggle}
                                >FlashCards</ListItem>
                                <ListItem component={NavLink} to="/profile"
                                    onClick={handleDrawerToggle}
                                >Profile</ListItem>
                                <ListItem component={NavLink} to="/allStudyGuides"
                                    onClick={handleDrawerToggle}
                                >Study Guides</ListItem>
                                <ListItem component={NavLink} to="/aboutUs"
                                    onClick={handleDrawerToggle}
                                >About Us</ListItem>
                                <ListItem onClick={() => {
                                    logout();
                                    handleDrawerToggle();
                                }}
                                    sx={{
                                        color: '#fff',
                                        background: 'linear-gradient(to right, #C04848 0%, #480048  51%, #C04848  100%);',
                                        padding: '12px 10px',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        maxWidth: '50%',
                                        margin: '45px auto 0',
                                        borderRadius: '5px'
                                    }}
                                >Logout</ListItem>
                            </List>
                        </Drawer>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
