import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API_URL } from '../../common/common';
import { CircularProgress } from '@mui/material';
import "./style.css";

const ResetPassword = () => {
    const [isResettingPassword, setIsResettingPassword] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const token = searchParams.get('token');
    // // // console.log("token ==> ", token);
    const [formData, setFormData] = useState({
        token: '',
        password: '',
        confirmPassword: ''
    });
    // // // console.log(formData)
    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    }
    useEffect(() => {
        setFormData({ ...formData, token })
    }, [token])

    const handleResetPasswordClick = (e) => {
        e.preventDefault();
        // // // // console.log('formData ==> ', formData);
        if (!formData.password) {
            Swal.fire({
                title: 'Error!',
                text: "password Should not be empty",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return
        }
        if (formData.password !== formData.confirmPassword) {
            Swal.fire({
                title: 'Error!',
                text: "Password and confirm password should be same",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return
        }


        handleSendingMail();
    }

    const handleSendingMail = async () => {
        try {
            setIsResettingPassword(true);
            const response = await fetch(API_URL + 'resetPassword ', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            // localStorage.setItem("studentId", data?.data?.user?._id)
            // // // console.log('data ==> ', data?.data?.user?._id);

            if (!data.status) {
                Swal.fire({
                    title: 'Error!',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }

            // if(data.)

            if (data.status) {
                Swal.fire({
                    title: 'Success!',
                    text: data.message,
                    icon: 'success',
                })

                navigate("/login")
            }
        } catch (error) {
            // // // console.log('error ==> ', error);
        } finally {
            setIsResettingPassword(false);
        }

    }
    return (
        <section className="resetAdminLogin">
            <div className="loginOuterBoxTop">
                <img className="img-fluid logoImg" src={require("../../assets/images/logo.png")} alt="" />
            </div>
            <div className="loginOuterBox form-signin ">
                <div className="loginInnerBox">

                    <form >
                        <div className="formBoxs">
                            <div className="formBox">
                                <input type="password" name="password" id="username" className="form-control"
                                    onChange={handleFormDataChange}
                                    placeholder="Password" required="" />
                            </div>
                            <div className="formBox">
                                <input type="password" name="confirmPassword" id="username" className="form-control"
                                    onChange={handleFormDataChange}
                                    placeholder="Confirm Password" required="" />
                            </div>
                        </div>
                        <div className="btnGroup">
                            <button className="btn btn-outline-light btn-lg btn-block form-group-3" style={{ fontWeight: 600 }}
                                onClick={(e) => handleResetPasswordClick(e)}
                            >
                                {isResettingPassword ? <CircularProgress size={30} /> : "Reset Password"}
                            </button>

                        </div>
                        {/* <div className="formBox" style={{ color: "white" }}>
                          new here? <span onClick={() => navigate("/register")} style={{ cursor: "pointer" }}>
                              Register
                          </span>
                      </div>
                      <div className="formBox" style={{ color: "white" }}>
                          Forgot Password? <span onClick={() => navigate("/forgotPassword")} style={{ cursor: "pointer" }}>
                              Reset Password
                          </span>
                      </div> */}
                    </form>
                </div>
            </div>
        </section>
    )
}

export default ResetPassword