import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API_URL } from "../common/common";

export const getUser = createAsyncThunk('user/getUser', async () => {
    try {
        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie.split('=')[1];

        const studentId = localStorage.getItem('studentId')

        const response = await fetch(API_URL + 'getStudent/' + studentId, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Server error!');
        }

        const data = await response.json();
        // // // console.log("getUser ", data)
        return data?.data;
    } catch (error) {
        throw new Error('Error in fetching topics!');
    }
});

const userSlice = createSlice({
    name: 'counter',
    initialState: {
        userData: null
    },
    reducers: {

        setUser: (state, action) => {
            state.userData = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(getUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getUser.fulfilled, (state, action) => {
                // // // console.log('action.payload ==> ', action.payload);
                state.status = 'succeeded';
                state.userData = action.payload;
            })
            .addCase(getUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
})

export const { setUser } = userSlice.actions
export default userSlice.reducer