import backgroundImage from '../../assets/images/cmn-all-website-bnr-bg.jpg';

export const styles = {
    verifyEmailOuter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        background: `url(${backgroundImage}) no-repeat center center fixed`,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardOuter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '30px 80px',
        gap: '25px',
        borderRadius: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        '& h2': {
            fontSize: '2em',
            fontWeight: 'bold',
        },
        '& button': {
            marginTop: '20px',
            borderRadius: '20px',
            border: 'none',
            color: '#fff',
            backgroundColor: '#3f51b5',
            padding: '10px 20px',
            fontSize: '1em',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#303f9f',
            },
        },
    },
}