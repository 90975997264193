import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

/* After login API Call (URL: /login, Method: POST, Body: {email, password}), if status is false, show the message received from the API in a sweetalert2 popup. If the reponse is like this:
{
    "code": 400,
    "status": false,
    "message": "Please verify your email!",
    "data": {
        "email": "loremipsum@gmail.com",
        "isEmailVerified": false
    }
}
Based on response.data.isEmailVerified, show a message from the response.message and stay on the same page. Next instruction is in /verifyEmail route.
*/


import Swal from 'sweetalert2'
// import { selectCurrentUser } from 'src/redux/reducers/userSlice'
// import { useAppSelector } from 'src/redux/hooks'
// import { useDispatch } from 'react-redux';
// import { setCurrentUser } from 'src/redux/reducers/userSlice'
// import { API_URL } from 'src/common/common'
import "./css/style.css"
import { API_URL } from '../../common/common';
import LoginHeader from '../../components/LoginHeader';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  //*** Redux Dispatch */
  // const dispatch = useDispatch();

  const navigate = useNavigate();

  // const currentUser = useAppSelector(selectCurrentUser)

  // // // // console.log('Current user: ', currentUser)

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const handleLoginClick = (e) => {
    e.preventDefault();
    // // // // console.log('formData ==> ', formData);
    if (!formData.email) {
      Swal.fire({
        title: 'Error!',
        text: "Email Should not be empty",
        icon: 'error',
        confirmButtonText: 'Ok'
      })
      return
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      Swal.fire({
        title: 'Error!',
        text: "Please enter a valid email address",
        icon: 'error',
        confirmButtonText: 'Ok'
      });
      return;
    }
    if (!formData.password) {
      Swal.fire({
        title: 'Error!',
        text: "Password Should not be empty",
        icon: 'error',
        confirmButtonText: 'Ok'
      })
      return
    }
    handleLogin();
  }

  const handleLogin = async () => {
    const response = await fetch(API_URL + 'login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: formData.email.toLowerCase().trim(), password: formData.password }),
    });

    const data = await response.json();
    localStorage.setItem("studentId", data?.data?.user?._id)
    // // // console.log('data ==> ', data?.data?.user?._id);



    if (!data.status && data?.data?.isEmailVerified === false) {
      Swal.fire({
        title: 'Email not verified!',
        text: 'Please verify your email to continue.',
        icon: 'warning',
        confirmButtonText: 'Resend Verification Email',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          const resendVerificationEmail = async () => {
            const emailVerificationResponse = await fetch(API_URL + 'verifyEmailRequest', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email: formData.email }),
            });
            const emailVerificationResponseData = await emailVerificationResponse.json();
            // localStorage.setItem("studentId", data?.data?.user?._id)
            // // // console.log('emailVerificationResponseData ==> ', emailVerificationResponseData);
            if (emailVerificationResponseData?.status) {
              Swal.fire({
                title: 'Success!',
                text: emailVerificationResponseData.message + "! Please verify your email to login.",
                icon: 'success',
                confirmButtonText: 'Ok'
              })
              navigate("/login")

            } else {

              Swal.fire({
                title: 'Error!',
                text: emailVerificationResponseData?.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }

          resendVerificationEmail();
        }
      });

    } else if (!data.status) {
      Swal.fire({
        title: 'Error!',
        text: data.message,
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }


    // if(data.)

    if (data.status) {
      // // // // console.log('data.data.user ==> ', data.data.user);

      // Admin cannot access student portal
      if (data.data.user.isAdmin) {
        Swal.fire({
          title: 'Error!',
          text: 'You are not authorized to access this page',
          icon: 'error',
          confirmButtonText: 'Ok'
        }).then(() => {
          document.cookie = `asvab-student-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          document.cookie = `asvab-student-details=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

          return;
        })
      } else {

        //   // Set a cookie that expires in 7 days

        const token = data.data.token;
        const expires = new Date();
        // expires.setTime(expires.getTime() + (60 * 60 * 1000)); // 1 hour in milliseconds
        const tokenExpiresAt = new Date(data.data.tokenExpiresAt * 1000); // Convert to milliseconds
        document.cookie = `asvab-student-token=${token}; expires=${tokenExpiresAt.toUTCString()}; path=/`;

        document.cookie = `asvab-student-details=${JSON.stringify(data.data.user)}; expires=${tokenExpiresAt.toUTCString()}; path=/`;

        //   // dispatch(setCurrentUser(data.data.user))
        //   navigate('/tests/all-tests');

        // }
        navigate("/")
      }
    }
  }

  return (
    <>
      {/* <LoginHeader/> */}
      <section className="adminLogin">
        <div className="loginOuterBoxTop">
          <img className="img-fluid logoImg" src={require("../../assets/images/logo.png")} alt="" />
          {/* <h2 className="h3 fw-normal" style={{ textAlign: 'center', color: 'white' }}>Elite Minds</h2> */}
          <h1 className="h3 mb-3 asvabHeading" style={{ textAlign: 'center', color: 'white' }}>ASVAB APP</h1>

          <div className="formBoxTextBtn" style={{ color: "white", marginTop: '10px', textAlign: 'center' }}>
            New Here? <span className='btnText' onClick={() => navigate("/register")} style={{ cursor: "pointer", textDecoration: 'underline' }}>
              Register
            </span>
          </div>

          <div className="formBox" style={{ color: "white", marginTop: '10px', textAlign: 'center' }}>
            OR
          </div>
          <h1 className="h3 LGin" style={{ textAlign: 'center', color: 'white' }}>LOGIN</h1>
        </div>
        <div className="loginOuterBox form-signin ">
          <div className="loginInnerBox">

            <form >
              <div className="formBoxs">
                <div className="formBox">
                  <input type="text" name="email" id="username" className="form-control"
                    onChange={handleFormDataChange}
                    placeholder="Email" required="" />
                </div>
                <div className="formBox">
                  <input type="password" name="password" id="password" className="form-control"
                    onChange={handleFormDataChange}
                    placeholder="Password" required="" />
                </div>
              </div>
              <div className="btnGroup">
                <button className="btn btn-outline-light btn-lg btn-block form-group-3" style={{ fontWeight: 600 }}
                  onClick={(e) => handleLoginClick(e)}>Log in</button>

              </div>
              <div className="formBox" style={{ color: "white", marginTop: '10px', textAlign: 'center' }}>
                Forgot Password? <span onClick={() => navigate("/forgotPassword")} style={{ cursor: "pointer", textDecoration: 'underline' }}>
                  Reset Password
                </span>
              </div>

            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default Login
