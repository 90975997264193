import React, { useEffect, useRef, useState } from 'react'
import "./style.css"
import { useDispatch, useSelector } from 'react-redux'
import { retakeTest, answerSubmit, decreamentCurrentQuestionIndex, getAllQuestions, getAllQuestionsByStudent, getFirstTenQuestions, getFirstTenQuestionsByStudent, incrementCurrentQuestionIndex, questionOfCurrentIndex, questionOfCurrentIndexUpdate, setCurrentQuestionIndex, setQuestionsToEmpty, getTests } from '../../redux/questionSlice'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import QuestionsBar from '../../components/QuestionsBar'
import Header from '../../components/Header'
import { CircularProgress } from '@mui/material'
import StableSideBar from '../../components/StableSideBar'
import { API_URL, BASE_URL } from '../../common/common'
const Questions = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate()
    // const history = useHistory();
    // const [searchParams, setSearchParams] = useState(new URLSearchParams());
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageParam, setPageParam] = useState()
    const [selectedOption, setSelectedOption] = useState(null);
    const [isReferenceOpen, setIsReferenceOpen] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isAnswerCorrect, setIsAnswerCorrect] = useState(null); // State to track if the answer is correct
    const [correctOption, setCorrectOption] = useState()
    const [explanation, setExplanation] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [reference, setReference] = useState()
    const questions = useSelector((state) => state.questions.questions)
    // const status = useSelector((state) => state.questions.status)
    const currentQuestionIndex = useSelector((state) => state.questions.currentQuestionIndex)
    const questionOfIndex = useSelector((state) => state.questions.questionOfIndex)
    const { testId } = useParams()

    const tests = useSelector((state) => state.questions.tests)
    // console.log("reference=>>>", reference)
    // console.log("tests", tests);

    const [sidebarHeight, setSidebarHeight] = useState(300);


    const userVerify = () => {
        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie?.split('=')[1];

        if (token) {

        } else {
            navigate("/login")
        }
    }
    const getReferenceByTestId = () => {
        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie?.split('=')[1];
        fetch(`${API_URL}getReferencesByTestId/${testId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }
        )
            .then((res) => res.json())
            .then((data) => {
                // console.log("data===> ", data)
                setReference(data.data)
            })

    }
    useEffect(() => {

        userVerify()
    }, [])
    useEffect(() => {
        setCorrectOption()
    }, [questionOfIndex])

    useEffect(() => {
        dispatch(questionOfCurrentIndexUpdate())

    }, [currentQuestionIndex, questions])

    useEffect(() => {


    }, [questions])




    useEffect(() => {
        if (questionOfIndex?.givenAnswer) {
            // // console.log("questionOfIndex?.givenAnswer", questionOfIndex)
            let givenAnswerIndex = questionOfIndex.options.findIndex(option => option === questionOfIndex.givenAnswer)
            // // // console.log("givenAnswerIndex", givenAnswerIndex)
            setSelectedOption(givenAnswerIndex);
            setIsSubmitted(true);
        } else {
            setSelectedOption(null)
            setIsSubmitted(false);
        }
    }, [questionOfIndex])

    useEffect(() => {
        // dispatch(getAllQuestions({ testId, page, limit })).then((e) => {
        //     //// // console.log(e)
        // })
        // dispatch(getAllQuestions({ testId })).then((e) => {
        //     //// // console.log(e)
        // })

        // dispatch(getFirstTenQuestions({ testId})).then((e) => {
        //     //// // console.log(e)
        //     dispatch(getAllQuestions({testId}))
        // })
        Promise.all([dispatch(getAllQuestionsByStudent({ testId })), dispatch(getTests()), getReferenceByTestId()])
            .then((res1, res2) => {
                // // console.log("res1 ", res1)
            })
            .catch((err) => {
                // // console.log("error===> ", err)
            })
            .finally(() => {
                setIsLoading(false)
            })

    }, [dispatch, testId])

    // Update search params when submitting a form, for example




    useEffect(() => {
        //// // console.log(currentQuestionIndex)
        // console.log("questionOfIndex", questionOfIndex)

    }, [currentQuestionIndex, questionOfIndex])

    useEffect(() => {
        //// // console.log(currentQuestionIndex)
        //// // console.log("questionOfIndex", questionOfIndex)
        dispatch(questionOfCurrentIndexUpdate())
    }, [currentQuestionIndex])

    //// // console.log("questions", questions)
    useEffect(() => {
        dispatch(questionOfCurrentIndexUpdate())
    }, [currentQuestionIndex])

    useEffect(() => {

    }, [])

    const handleChange = event => {
        // // console.log(parseInt(event.target.value))
        // setSelectedOption(parseInt(event.target.value));

        handleSubmit(parseInt(event.target.value));
    };

    const handleNextClick = () => {
        // dispatch(incrementCurrentQuestionIndex());
        setIsSubmitted(false);
        setSelectedOption(null);
        setIsAnswerCorrect(null);

        setCorrectOption(null)
        // setSearchParams({ page: parseInt(pageParam)+1 });
        setCorrectOption(null)
        dispatch(incrementCurrentQuestionIndex())
        setExplanation(null)

    };

    const handlePreviousClick = () => {
        // dispatch(decreamentCurrentQuestionIndex());
        setIsSubmitted(false);
        setSelectedOption(null);
        setIsAnswerCorrect(null);
        setCorrectOption(null)
        // setSearchParams({ page: parseInt(pageParam) - 1 });
        dispatch(decreamentCurrentQuestionIndex())
        setExplanation(null)
    };

    useEffect(() => {
        // // // console.log('Is Loading:', isLoading);
        if (!isLoading) {
            const mainDivHeight = document.querySelector('.question-starting-main').offsetHeight;
            // // // console.log('Main Div Height:', mainDivHeight);
            setSidebarHeight(mainDivHeight);
        }
    }, [isLoading, handleNextClick, handlePreviousClick])

    // // // console.log('Sidebar Height:', sidebarHeight);

    // // console.log("selectedOption ", selectedOption)
    const handleSubmit = async (selectedOption) => {

        // if (selectedOption !== 0 && !selectedOption) {
        //     Swal.fire({
        //         title: 'Error!',
        //         text: "select an option",
        //         icon: 'error',
        //         confirmButtonText: 'Ok'
        //     })
        //     return
        // }

        setIsSubmitted(true);
        // Find the index of the correct option based on its content
        // const correctOptionIndex = questionOfIndex.options.findIndex(option => option === questionOfIndex.answer);
        // setCorrectOption(correctOptionIndex)
        // //// // console.log("correctOptionIndex, selectedOption",correctOptionIndex, selectedOption)
        // Check if the selected option matches the correct option index

        //// // console.log("handlesubmit called")


        dispatch(answerSubmit({ testId, questionId: questionOfIndex._id, answer: questionOfIndex.options[selectedOption] })).then((e) => {
            // // console.log("answer submit response === > ", e.payload.isCorrect)
            // console.log("answerSubmit err ", e)
            if (e.error) {
                Swal.fire({
                    title: 'Error!',
                    text: e.error.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    showCancelButton: true, // Add this line to show the cancel button
                    cancelButtonText: 'Retake Test' // Customize the cancel button text
                }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.cancel) {
                        retakeTheTest(); // Call the retakeTest function when the cancel button is clicked
                    }
                });
                return;
            }
            setIsAnswerCorrect(e?.payload?.isCorrect)
            const correctOptionIndex = questionOfIndex.options.findIndex(option => option.trim() === e?.payload?.correctAnswer.trim());
            setCorrectOption(correctOptionIndex)
            setExplanation(e?.payload?.explanation)
        }).catch((err) => {
            // // // console.log("answerSubmit err ",err)
        })



    };
    const handleSubmitTestClick = () => {
        // // // console.log("sumit test clicked")
        navigate(`/submitTest/${testId}`)
    }

    const retakeTheTest = () => {
        // // console.log("retake test .....  ... .  . .")
        setIsLoading(true)
        dispatch(retakeTest({ testId })).then((e) => {
            // // console.log("retakeTheTest dispatch response ", e)
            dispatch(setQuestionsToEmpty())
            if (e.type === "test/retakeTest/fulfilled") {
                dispatch(getAllQuestionsByStudent({ testId })).then((e) => {
                    // // console.log("getAllQuestionsByStudent,==> ", e)
                    setIsLoading(false)
                })
                dispatch(setCurrentQuestionIndex(0))
                navigate(`/questions/${testId}`)
            }
        })
    }
    const addABCDinOptions = ({ option, index }) => {
        // // console.log("optionIndex ", index)
        if (index === 0) {
            return "<p>A:</p>"
        }
        if (index === 1) {
            return "<p>B:</p>"
        }
        if (index === 2) {
            return "<p>C:</p>"
        }
        if (index === 3) {
            return "<p>D:</p>"
        }

    }
    const showOptionLetterIndex = (index) => {
        if (index === 0) {
            return "A"
        }
        if (index === 1) {
            return "B"
        }
        if (index === 2) {
            return "C"
        }
        if (index === 3) {
            return "D"
        }
    }

    const getTestName = () => {
        const testName = tests.find(test => test._id === testId)?.name

        if (!testName) {
            return "Test"
        }

        return testName
    }

    return (
        <>
            {/* <QuestionsBar/> */}
            <Header questionsPage={true} />



            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20%' }}>

                    <CircularProgress value={25} />
                </div>
            ) :

                <div className='questions-option-main-sec'
                    onClick={() => setIsReferenceOpen(false)}
                >

                    <div className='container'>

                        <h2 className='test-heading'>{getTestName()}</h2>
                        {reference && reference.length !== 0 &&
                            <div className='last-btn-prev-next-sec-ref'>

                                <button
                                    style={{
                                        position: 'relative',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setIsReferenceOpen(!isReferenceOpen);
                                    }}
                                >
                                    References
                                    {isReferenceOpen &&
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '47px',
                                                left: '-81px',
                                                width: '300px',
                                                backgroundColor: '#f7f96f',
                                                borderRadius: '5px',
                                                zIndex: '1000',
                                            }}
                                        >
                                            <ul
                                                className='ref-list-ul'
                                                style={{
                                                    listStyle: 'none',
                                                    padding: '0',
                                                    marginBottom: '0',
                                                    maxHeight: '300px',
                                                    overflowY: 'auto',
                                                }}
                                            >
                                                {reference.map((ref, index) => (
                                                    <li
                                                        key={index}
                                                        className='ref-list'
                                                        style={{
                                                            textAlign: 'left',
                                                            padding: '10px',
                                                            wordWrap: 'break-word',
                                                            borderBottom: index === reference.length - 1 ? 'none' : '1px solid #000',
                                                        }}
                                                        onClick={() => {
                                                            setIsReferenceOpen(false);
                                                            navigate(`/referencePdf`, { state: { item: ref } })
                                                        }}
                                                    >
                                                        {ref.name}
                                                    </li>
                                                ))}

                                            </ul>
                                        </div>
                                    }

                                </button>

                            </div>
                        }
                        <div className='subject-header'>
                            <h3>Subject: {questionOfIndex?.subjectName}</h3>
                        </div>

                        <div className='flex-question-sidebars-sec row'>

                            <div className='col-lg-9 col-md-9 col-sm-12'>
                                <div
                                    className='question-starting-main'
                                >
                                    <div className='all-questions-inr-options'>
                                        <h6>Question no {parseInt(questionOfIndex?.questionIndex)}.</h6>
                                        <h6 dangerouslySetInnerHTML={{ __html: questionOfIndex?.questionName }} ></h6>
                                        <div className='questions-options'>
                                            <div className='row'>
                                                {!isLoading && questionOfIndex ? questionOfIndex?.options?.map((option, index) => (
                                                    <div className={`col-lg-6 col-md-6 col-sm-12 `} key={index}>

                                                        <div className={isSubmitted ? (isAnswerCorrect ? (selectedOption === index ? 'answer-details-inr bg-success' : 'answer-details-inr ') : (selectedOption === index ? 'answer-details-inr' : (correctOption === index ? 'answer-details-inr bg-success' : 'answer-details-inr '))) : 'answer-details-inr '}>

                                                            <input
                                                                type="radio"
                                                                id={`option-${index}`}
                                                                name="all-input-radio-btn"
                                                                value={index}
                                                                onChange={handleChange}
                                                                checked={selectedOption === index}
                                                                disabled={isSubmitted}

                                                            />
                                                            <label
                                                                htmlFor={`option-${index}`}
                                                                dangerouslySetInnerHTML={{ __html: addABCDinOptions({ option, index }) }}
                                                                className={'ms-3 correct-answer'}
                                                            ></label>
                                                            <label
                                                                htmlFor={`option-${index}`}
                                                                dangerouslySetInnerHTML={{ __html: option }}
                                                                className={'ms-3 correct-answer'}
                                                            ></label>

                                                        </div>
                                                    </div>
                                                )) : !isLoading && !questionOfIndex ? (
                                                    <>
                                                        Question Not Available
                                                    </>
                                                ) :

                                                    (
                                                        <>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                                                <CircularProgress value={25} />
                                                            </div>

                                                        </>
                                                    )}


                                            </div>
                                        </div>
                                        {/* Display explanation if the answer is wrong */}
                                        {isSubmitted && explanation && (correctOption || correctOption === 0) && (
                                            <div className="explanation-section">
                                                <h3>Correct Answer: {showOptionLetterIndex(correctOption)} </h3>
                                                <div dangerouslySetInnerHTML={{ __html: questionOfIndex.options[correctOption] }} />
                                                <h3>Explanation:</h3>
                                                <div dangerouslySetInnerHTML={{ __html: explanation }} />
                                            </div>
                                        )}
                                    </div>
                                    {/* last submit next btn sec */}
                                    <div className='last-submit-next-btn-sec'>
                                        <button className='prev-btn' onClick={handlePreviousClick}
                                            style={{
                                                background: currentQuestionIndex === 0 && 'rgba(187, 84, 0, 0.24)',
                                                cursor: currentQuestionIndex === 0 && 'not-allowed',
                                                pointerEvents: currentQuestionIndex === 0 && 'none',
                                                border: currentQuestionIndex === 0 && '1px solid rgba(187, 84, 0, 0.06)'
                                            }}
                                        >Previous</button>
                                        {/* {!isSubmitted && !questionOfIndex?.givenAnswer && <button type='button' className='submit-btn' onClick={handleSubmit} disabled={isSubmitted}>Submit</button>} */}
                                        {currentQuestionIndex === questions.length - 1 ? (
                                            <button className='next-btn' onClick={handleSubmitTestClick}>Submit Test</button>

                                        ) : (
                                            <button className='next-btn' onClick={handleNextClick} disabled={currentQuestionIndex === questions.length - 1}>Next</button>
                                        )}
                                    </div>

                                </div>
                            </div>

                            <div className='col-lg-3 col-md-3 col-sm-12 '

                            >
                                <StableSideBar sidebarHeight={sidebarHeight} />
                            </div>


                        </div>


                        {/* last submit next btn sec */}

                    </div>
                </div>
            }

        </>
    )
}

export default Questions


