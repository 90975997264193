import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser, setUser } from '../../redux/userSlice'
import { API_URL, BASE_URL } from '../../common/common'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Header from '../../components/Header'
import { Tooltip } from '@mui/material'

const Profile = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const userData = useSelector((state) => state.user.userData)
    // // // console.log("userData profile", userData);
    const [editEmail, setEditEmail] = useState(false)
    const [editProfile, setEditProfile] = useState(false)
    const [image, setImage] = useState(null);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        name: '',
        confirmPassword: '',
        image: null
    });

    const getUserDetails = () => {
        dispatch(getUser())
    }
    const fillUserDetails = () => {

        if (userData) {
            setFormData({ ...formData, email: userData.email, name: userData.name, image: userData.image && userData.image })
            setEditEmail(false)

            setEditProfile(false)
        }

    }
    useEffect(() => {
        getUserDetails()
    }, [])
    useEffect(() => {
        fillUserDetails()
    }, [userData])

    useEffect(() => {
        // // // console.log("formData profile", formData);
    }, [formData])
    const handleImageUpload = (event) => {
        const selectedImage = event.target.files[0];

        if (selectedImage.size > 1000000) {
            Swal.fire({
                title: 'Error!',
                text: "Image size should be less than 1MB",
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }


        // setImage(URL.createObjectURL(selectedImage));
        setImage(selectedImage)
    };

    const handleFormDataChange = (e) => {
        e.preventDefault()
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    // // // console.log("editEmail", editEmail)
    const handleEditEmail = () => {
        // // // console.log("handleEditEmail")
        setEditEmail(!editEmail)
        setFormData({ ...formData, email: userData.email })
    }
    const updateEmail = async () => {
        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie.split('=')[1];
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            Swal.fire({
                title: 'Error!',
                text: "Please enter a valid email address",
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }

        const response = await fetch(API_URL + 'updateEmailRequest ', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: formData.email }),
        });
        const data = await response.json();
        if (data.status) {

            Swal.fire({
                title: 'Success!',
                text: data.message + "! Please verify your email to login.",
                icon: 'success',
                confirmButtonText: 'Ok'
            })
            navigate("/login")

        } else {

            Swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }

        if (response.status === true) {
            navigate("/login")
        }


        // localStorage.setItem("studentId", data?.data?.user?._id)
        // // // console.log('updateEmail response ==> ', data);

        setEditEmail(false)
    }
    const cancelProfileEdit = () => {
        setFormData({ ...formData, name: userData.name, password: '', confirmPassword: '' })
        setImage(null)
        setEditProfile(false)
    }

    const handleEditProfileButtonClick = () => {
        setEditProfile(true)
    }
    const updateProfile = async () => {
        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie.split('=')[1];
        const studentId = localStorage.getItem("studentId")
        let profileData = new FormData();

        // profileData.name=formData.name
        profileData.append("name", formData.name)
        if (formData.password && formData.confirmPassword) {
            profileData.append("password", formData.password)
        }
        if (image) {
            profileData.append("file", image)
        }
        // // // console.log(profileData)
        if (formData.password !== formData.confirmPassword) {
            Swal.fire({
                title: 'Error!',
                text: "Confirm Password Should be same",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return
        }
        if (!formData.name) {
            Swal.fire({
                title: 'Error!',
                text: "Fill up name",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return
        }


        const response = await fetch(API_URL + `updateStudent/${studentId}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                // 'Content-Type': 'application/json',
            },
            body: profileData,
        });
        const data = await response.json();
        if (data.status) {

            Swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                confirmButtonText: 'Ok'
            })
            // dispatch(getUserDetails())
            // navigate("/login")

            dispatch(setUser(data.data));

        } else {

            Swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }

        // // // console.log("updateProfile res", data)
        setEditProfile(false)
    }

    return (<>
        <Header />
        <div className='profile-main-sec'>
            <div className='container'>
                <div className='profile-inr-sec'>
                    <div className='profile-upload-img-sec'>
                        <figure>
                            {image && formData.image ?
                                <img src={URL.createObjectURL(image)} alt="Uploaded" />
                                : !image && formData.image ?
                                    <img src={BASE_URL + formData.image} alt="Uploaded" /> :
                                    <img src={require('../../assets/images/default_profile.png')} alt='' />
                            }
                            {editProfile &&
                                <Tooltip title="Upload Image">
                                    <button className='edit-onlyprofile-btn-one'>
                                        <img src={require('../../assets/images/edit-icon1.png')}
                                            alt=''
                                            onClick={() => document.getElementById('imageUpload').click()}
                                        />
                                    </button>
                                </Tooltip>
                            }

                            {/* Hidden input field for uploading the image */}
                            <input
                                type="file"
                                id="imageUpload"
                                style={{ display: 'none' }}
                                onChange={handleImageUpload}
                            />

                        </figure>
                    </div>
                    <div className='profile-details-sec-inr'>
                        <div className='form-group-profile'>
                            <input type='text'
                                placeholder='Name'
                                name='name'
                                disabled={!editProfile}
                                value={formData.name}
                                onChange={handleFormDataChange}
                                style={{
                                    cursor: editProfile ? 'text' : 'not-allowed',
                                    opacity: editProfile ? 1 : 0.5
                                }}
                            />
                        </div>
                        <div className='form-group-profile'>
                            <input
                                type='password'
                                name='password'
                                disabled={!editProfile}
                                placeholder='Password'
                                value={formData.password}
                                onChange={handleFormDataChange}
                                style={{
                                    cursor: editProfile ? 'text' : 'not-allowed',
                                    opacity: editProfile ? 1 : 0.5
                                }}
                            />
                        </div>
                        <div className='form-group-profile'>
                            <input
                                type='password'
                                name='confirmPassword'
                                placeholder='Confirm Password'
                                disabled={!editProfile}
                                value={formData.confirmPassword}
                                onChange={handleFormDataChange}
                                style={{
                                    cursor: editProfile ? 'text' : 'not-allowed',
                                    opacity: editProfile ? 1 : 0.5
                                }}
                            />
                        </div>
                        <div className='last-group-btn-sec-profile'>
                            {!editProfile ? (
                                <button type='button'
                                    className='edit-profile-btn-one'
                                    onClick={handleEditProfileButtonClick}
                                >
                                    Edit Profile
                                </button>) : (<><button
                                    type='button'
                                    className='update-profile-btn-one'
                                    onClick={updateProfile}
                                >

                                    Update Profile
                                </button>
                                    <button
                                        type='button'
                                        className='update-profile-btn-one'
                                        onClick={cancelProfileEdit}
                                    >

                                        Cancel
                                    </button>

                                </>
                            )}



                        </div>
                        <div className='form-group-profile'>

                            <input type='email'
                                placeholder='Email: saikat12345@gmail.com'
                                name='email'
                                value={formData.email}
                                onChange={handleFormDataChange}
                                disabled={!editEmail}
                                style={{
                                    cursor: editEmail ? 'text' : 'not-allowed',
                                    opacity: editEmail ? 1 : 0.5
                                }}
                            />
                            {editEmail ? (<>
                                <Tooltip title="Close">
                                    <button
                                        className='close-update-profile-btn-two'
                                        onClick={handleEditEmail}
                                    >
                                        <img src={require('../../assets/images/close-profile-icon1.png')} alt='' />
                                    </button>
                                </Tooltip>

                                <Tooltip title="Update Email">
                                    <button
                                        className='edit-update-profile-btn-two'
                                        onClick={updateEmail}
                                    >
                                        <img src={require('../../assets/images/checked.png')} alt='' />
                                    </button>
                                </Tooltip>
                            </>) : (
                                <Tooltip title="Edit Email">
                                    <button
                                        className='edit-update-profile-btn-one'
                                        onClick={handleEditEmail}

                                    >
                                        <img src={require('../../assets/images/edit-icon1.png')} alt='' />
                                    </button>
                                </Tooltip>
                            )}




                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>

    )
}

export default Profile