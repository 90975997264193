
export const styles = {
    aboutUsHeader: {
        textAlign: 'center',
        color: '#fff',
        marginBottom: '20px',
        textShadow: '1px 2px 4px #000000',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '35px',
        lineHeight: '36px'
    },
    aboutUsDetailsinrH4: {
        textAlign: 'center',
        color: '#fff',
        marginBottom: '40px',
        textShadow: '1px 2px 4px #000000',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '30px',
        lineHeight: '34px'
    },
    AllParaAbtPages: {
        color: '#000',
        marginBottom: '15px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '28px'
    },
    AllParaAbtPagesFirst: {
        color: '#fff',
        marginBottom: '15px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '28px',
        width: '91%',
        margin: '0 auto',
    },
    StrongsAbtPara: {
        fontWeight: '600',
        fontSize: '22px',
        display: 'block',
        marginBottom: '10px',
        textTransform: 'capitalize',
        textShadow: '1px 1px 3px rgb(1 0 82)',
    },
    AboutUsDetailsInr: {
        textAlign: 'center',
        padding: '40px 10px 40px',
    },
    AboutDetailsInrH3: {
        color: '#fff',
        marginBottom: '25px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '28px',
        lineHeight: '30px'
    },
    StepsInrAbtpage: {
        boxShadow: 'rgb(9 0 60 / 67%) 0px 0px 15px',
        padding: '25px 20px',
        borderRadius: '8px 30px 8px 30px',
        background: '#fff',
        // minHeight: '270px',
        marginBottom: '20px'
    },
    AnchorTagsStyle: {
        color: 'rgb(2 0 185)',
        textDecoratation: 'underline',
        fontSize: '20px'
    },
    submitButtonOuterBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '25px'
    },
    submitButtonInnerBox: {
        width: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    submitButton: {
        background: '#1f9962',
        color: '#FFFFFF',
        padding: '10px 20px',
        fontSize: '18px',
        fontWeight: '600',
        textTransform: 'capitalize',
        boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 10px',
        '&:hover': {
            background: '#24b775',
            color: '#FFFFFF',
        }
    }
};