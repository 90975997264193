import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_URL } from '../common/common';
import Swal from 'sweetalert2';

export const getAllQuestions = createAsyncThunk('test/getQuestions', async ({ testId, page, limit }) => {
    try {
        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie.split('=')[1];
        // if(!page){
        //     page=1
        // }
        // if (!limit) {
        //     limit = Number.MAX_SAFE_INTEGER
        // }
        // // console.log("testId, page, limit,", testId, page, limit)
        // + `?page=${page}&limit=${limit}`
        const response = await fetch(API_URL + 'getAllQuestions/' + testId, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        // // console.log(response)
        if (!response.ok) {
            throw new Error('Server error!');
        }

        const data = await response.json();

        return data?.data;
    } catch (error) {
        throw new Error('Error in fetching questions!');
    }
});

export const getFirstTenQuestions = createAsyncThunk('test/getTenQuestions', async ({ testId, page, limit }) => {
    try {
        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie.split('=')[1];
        if (!page) {
            page = 1
        }
        if (!limit) {
            limit = 10
        }
        // // console.log("testId, page, limit,", testId, page, limit)
        const response = await fetch(API_URL + 'getAllQuestions/' + testId + `?page=${page}&limit=${limit}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        // // console.log(response)
        if (!response.ok) {
            throw new Error('Server error!');
        }

        const data = await response.json();

        return data?.data;
    } catch (error) {
        throw new Error('Error in fetching questions!');
    }
});

export const getFirstTenQuestionsByStudent = createAsyncThunk('test/getTenQuestionsByStudent', async ({ testId, page, limit }) => {
    try {
        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie.split('=')[1];
        if (!page) {
            page = 1
        }
        if (!limit) {
            limit = 10
        }
        let studentId = localStorage.getItem("studentId")
        // // console.log("testId, page, limit,", testId, page, limit, studentId)
        const response = await fetch(API_URL + 'getQuestions/' + testId + '/' + studentId + `?page=${page}&limit=${limit}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        // // console.log("getFirstTenQuestionsByStudent===> ", response)
        if (!response.ok) {
            throw new Error('Server error!');
        }

        const data = await response.json();

        return data?.data;
    } catch (error) {
        throw new Error('Error in fetching questions!');
    }
});

export const getAllQuestionsByStudent = createAsyncThunk('test/getAllQuestionsByStudent', async ({ testId, page, limit }) => {
    try {
        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie.split('=')[1];
        // if (!page) {
        //     page = 1
        // }
        // if (!limit) {
        //     limit = 10
        // }
        let studentId = localStorage.getItem("studentId")
        // // console.log("testId, page, limit,", testId, page, limit, studentId)
        const response = await fetch(API_URL + 'getQuestions/' + testId + '/' + studentId, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        // // console.log("getFirstAllQuestionsByStudent==> ", response)
        if (!response.ok) {
            throw new Error('Server error!');
        }

        const data = await response.json();

        return data?.data;
    } catch (error) {
        throw new Error('Error in fetching questions!');
    }
});

export const answerSubmit = createAsyncThunk('test/answerSubmit', async ({ testId, questionId, answer }) => {
    try {
        // // console.log("answerSubmit....")
        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie.split('=')[1];
        // if (!page) {
        //     page = 1
        // }
        // if (!limit) {
        //     limit = 10
        // }
        let studentId = localStorage.getItem("studentId")
        // // console.log("testId, page, limit,", testId, studentId, answer, studentId)
        // // console.log("testId, page, limit,", typeof (testId), typeof (studentId), typeof (answer), typeof (studentId))
        const response = await fetch(API_URL + 'answerQuestion/', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                testId: testId,
                studentId: studentId,
                questionId: questionId,
                answer: answer
            })
        });
        // // // console.log("getFirstAllQuestionsByStudent==> ", response)
        const data = await response.json();
        // // console.log("getFirstAllQuestionsByStudent==> ", data)
        if (!response.ok) {
            throw new Error(data.message);
        }

        // wrong ans respons
        // {
        //     "code": 200,
        //         "status": true,
        //         "message": "Answer submitted successfully.",
        //         "data": {
        //              "isCorrect": false,
        //              "correctAnswer": "<p>22</p>",
        //              "explanation": "<p><strong>D. </strong>Perform operations in the parentheses first, then the exponents, and then the remaining operations.</p><p>8 + 2(7)&nbsp;</p><p>8 + 14 = 22</p>"
        //     }
        // }

        //right answer response
        // {
        //     "code": 200,
        //         "status": true,
        //         "message": "Answer submitted successfully.",
        //         "data": {
        //              "isCorrect": true,
        //              "correctAnswer": "<p>c = 6; d = 2</p>",
        //              "explanation": "<p><strong>C.</strong> Set each equation to equal zero. Next, arrange the equations so that when one is subtracted from or added to the other, one of the terms results in a zero and drops out of the result. Next, solve for the remaining unknown. In this problem,&nbsp;</p><p>6c - 2d - 32&nbsp;</p><p>3c + 2d - 22&nbsp;</p><p>Set each equation to zero.&nbsp;</p><p>6c - 2d - 32 = 0&nbsp;</p><p>3c + 2d - 22 = 0&nbsp;</p><p>Note that if you add the two equations, the d term drops out, leaving 9c - 54 = 0. Solve for c: 9c = 54; c = 6&nbsp;</p><p>Substitute for c in one of the equations to solve for d.&nbsp;</p><p>6(6) - 2d - 32 = 0&nbsp;</p><p>36 - 2d - 32 = 0&nbsp;</p><p>-2d = 32 - 36&nbsp;</p><p>-2d = -4&nbsp;</p><p>d = 2&nbsp;</p><p>The correct answer is c = 6; d = 2.</p>"
        //     }
        // }



        return data?.data;
    } catch (error) {
        // // console.log("submit thunk error", error)
        throw new Error(error.message);
    }
});

export const getTests = createAsyncThunk('test/getTests', async () => {
    try {
        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie.split('=')[1];

        const response = await fetch(API_URL + 'getAllTests', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Server error!');
        }

        const data = await response.json();

        return data?.data;
    } catch (error) {
        throw new Error('Error in fetching topics!');
    }
});

export const retakeTest = createAsyncThunk('test/retakeTest', async ({ testId }) => {
    try {
        // // console.log("answerSubmit....")
        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie.split('=')[1];
        // if (!page) {
        //     page = 1
        // }
        // if (!limit) {
        //     limit = 10
        // }
        let studentId = localStorage.getItem("studentId")
        // // console.log("testId, page, limit,", testId, studentId)
        // // // console.log("testId, page, limit,", typeof (testId), typeof (studentId), typeof (answer), typeof (studentId))
        let response = await fetch(API_URL + 'retakeTest/', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                testId,
                studentId
            })
        });
        // // // console.log("getFirstAllQuestionsByStudent==> ", response)
        const data = await response.json();
        // // console.log("getFirstAllQuestionsByStudent==> ", data)
        if (!response.ok) {
            throw new Error(data.message);
        }
        return data?.data;
    } catch (error) {
        // // console.log("Retake Test Error", error)
        throw new Error(error.message);
    }
});
export const getStudyGuides = createAsyncThunk('studyGuides/getStudyGuides', async () => {
    try {
        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie.split('=')[1];
        const response = await fetch(API_URL + 'getStudyGuides', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error('Server error!');
        }

        const data = await response.json();

        return data?.data;
    } catch (error) {
        throw new Error('Error in fetching topics!');
    }
})

export const questionSlice = createSlice({
    name: 'counter',
    initialState: {
        tests: [],
        questions: [],
        currentQuestionIndex: 0,
        questionOfIndex: {},
        studyGuides: [],
        status: 'loading'
    },
    reducers: {
        incrementCurrentQuestionIndex: state => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.currentQuestionIndex += 1
        },
        decreamentCurrentQuestionIndex: state => {
            state.currentQuestionIndex -= 1
        },
        questionOfCurrentIndexUpdate: state => {
            // // // console.log("state.questions[state.currentQuestionIndex]",state.questions[state.currentQuestionIndex])
            state.questionOfIndex = state.questions[state.currentQuestionIndex]
        },
        setCurrentQuestionIndex: (state, action) => {
            // // console.log("setCurrentQuestionIndex", action.payload)
            state.currentQuestionIndex = action.payload
            // state.questionOfIndex = state.questions[action.payload]
        },
        setQuestionsToEmpty: (state, action) => {
            state.questions = []
        }
    },


    extraReducers: (builder) => {
        builder
            .addCase(getTests.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getTests.fulfilled, (state, action) => {
                // // // console.log('action.payload ==> ', action.payload);
                state.status = 'succeeded';
                state.tests = action.payload;
            })
            .addCase(getTests.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(getAllQuestions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAllQuestions.fulfilled, (state, action) => {
                // // console.log('action.payload ==> ', action.payload.questionsWithOptions);
                action.payload.questionsWithOptions.forEach((element, i) => {
                    if (i > 9) {
                        state.questions.push(element)
                    }

                });
                // state.questions = action.payload.questionsWithOptions
                state.status = 'succeeded';
                // state.questions = action.payload;
            })
            .addCase(getAllQuestions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });

        builder
            .addCase(getFirstTenQuestions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getFirstTenQuestions.fulfilled, (state, action) => {
                // // console.log('action.payload ==> ', action.payload.questionsWithOptions);
                state.questions = action.payload.questionsWithOptions
                state.status = 'succeeded';
                // state.questions = action.payload;
            })
            .addCase(getFirstTenQuestions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(getFirstTenQuestionsByStudent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getFirstTenQuestionsByStudent.fulfilled, (state, action) => {
                // // console.log('action.payload ==> ', action.payload.questionsWithOptions);
                state.questions = action.payload.questionsWithOptions
                state.status = 'succeeded';
                // state.questions = action.payload;
            })
            .addCase(getFirstTenQuestionsByStudent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(getAllQuestionsByStudent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAllQuestionsByStudent.fulfilled, (state, action) => {
                // // console.log('getAllQuestionsByStudent action.payload ==> ', action.payload);

                for (const subjectWithQuestions of action.payload.subjectsWithQuestions) {
                    for (const question of subjectWithQuestions.questions) {
                        const isQuestionExist = state.questions.find((q) => q._id === question._id);

                        if (!isQuestionExist) {
                            state.questions.push(question);
                        }
                    }
                }
                // state.questions = action.payload.questionsWithOptions
                state.status = 'succeeded';
                // state.questions = action.payload;
            })
            .addCase(getAllQuestionsByStudent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(answerSubmit.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(answerSubmit.fulfilled, (state, action) => {
                // // console.log('answerSubmit action.payload ==> ', action.payload);
                state.questions[state.currentQuestionIndex].givenAnswer = action.payload.givenAnswer
                // state.questionOfIndex=
                // state.questions = action.payload.questionsWithOptions
                state.status = 'succeeded';
                // state.questions = action.payload;
            })
            .addCase(answerSubmit.rejected, (state, action) => {
                // // console.log("answerSubmit action.error", action.error)
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(retakeTest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(retakeTest.fulfilled, (state, action) => {
                // // console.log('answerSubmit action.payload ==> ', action.payload);
                // state.questions = []
                // state.questionOfIndex=
                // state.questions = action.payload.questionsWithOptions
                state.status = 'succeeded';
                // state.questions = action.payload;
            })
            .addCase(retakeTest.rejected, (state, action) => {
                // // console.log("answerSubmit action.error", action.error)
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(getStudyGuides.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getStudyGuides.fulfilled, (state, action) => {
                // // console.log('getStudyGuides action.payload ==> ', action.payload);
                state.status = 'succeeded';
                state.studyGuides = action.payload;
            })
            .addCase(getStudyGuides.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });

    }
})

export const {
    incrementCurrentQuestionIndex,
    questionOfCurrentIndexUpdate,
    decreamentCurrentQuestionIndex,
    setCurrentQuestionIndex,
    setQuestionsToEmpty } = questionSlice.actions

export default questionSlice.reducer