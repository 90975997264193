import { configureStore } from '@reduxjs/toolkit'
import questionSlice from './questionSlice'
import flashCardSlice from './flashCardSlice'
import userSlice from './userSlice'

export default configureStore({
    reducer: {
        questions: questionSlice,
        flashCards: flashCardSlice,
        user: userSlice
    }
})