import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentQuestionIndex } from '../redux/questionSlice';
import { Box } from '@mui/material';

const StableSideBar = ({ sidebarHeight }) => {
    const [open, setOpen] = React.useState(true);
    const questions = useSelector((state) => state.questions.questions)
    const currentQuestionIndex = useSelector((state) => state.questions.currentQuestionIndex)
    // const questionOfIndex = useSelector((state) => state.questions.questionOfIndex)
    const dispatch = useDispatch();
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    // // // // console.log("Question Bar questions,",questions)
    const questionMenuClick = (i) => {
        // // // console.log("question Menu C")
        dispatch(setCurrentQuestionIndex(i))
        // questionOfCurrentIndexUpdate(i)
    }


    return (
        <Box className='stable-sidebar-main-sec'
            sx={{
                height: {
                    xs: '300px',
                    sm: '300px',
                    md: '100%',
                },
            }}
        >
            <div className='stable-sidebar-inr'>
                <div className='heading__sidebarbox'>
                    <h3>Questions: </h3>
                </div>
                <Box className='sidebar__question-items'
                    sx={{
                        maxHeight: {
                            xs: '220px',
                            sm: '220px',
                            md: sidebarHeight - 92 + 'px',
                            lg: sidebarHeight - 92 + 'px',
                            xl: sidebarHeight - 92 + 'px',
                        }
                    }}
                >
                    <ul>
                        {questions && questions.map((question, i, questionsArray) => (
                            <React.Fragment key={i}>
                                {questionsArray[i].questionIndex === 1 &&
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                        <h5>{question.subjectName}</h5>
                                    </div>
                                }
                                <li
                                    onClick={() => questionMenuClick(i)}
                                    className={i == currentQuestionIndex ? "active__question-set" : (question.givenAnswer ? '' : 'unattempted__questions-set')}>
                                    {parseInt(question?.questionIndex)}
                                </li>
                            </React.Fragment>
                            // <ListItem key={i} disablePadding
                            //     sx={style.listingItemsSidebars}
                            // >
                            //     <ListItemButton
                            //         onClick={() => questionMenuClick(i)}
                            //         sx={{
                            //             background: question.givenAnswer ? '#fcff6b' : '#fff',
                            //             border: currentQuestionIndex === i ? '1px solid #fff' : 'none',
                            //             boxShadow: currentQuestionIndex === i ? 'inset 0 200px #00ab45' : '0 0 0px #ebffe5',
                            //             color: currentQuestionIndex === i ? '#fff' : '#181818',
                            //             borderRadius: '30px',
                            //         }}
                            //     >
                            //         <ListItemText sx={style.liListingDivSidebar} primary={i + 1} />
                            //     </ListItemButton>
                            // </ListItem>
                        ))}
                    </ul>
                </Box>
            </div>
        </Box>
    )
}

export default StableSideBar