import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_URL } from '../common/common';

export const getAllFlashCards = createAsyncThunk('/getAllFlashCards', async ({ subjectId, page, limit }) => {
    try {

        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie.split('=')[1];
        // if(!page){
        //     page=1
        // }
        // if (!limit) {
        //     limit = Number.MAX_SAFE_INTEGER
        // }
        // // // console.log("subjectId, page, limit,", subjectId, page, limit)
        // + `?page=${page}&limit=${limit}`
        const response = await fetch(API_URL + 'getFlashCardsBySubjectId/' + subjectId, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        // // // console.log(response)
        if (!response.ok) {
            throw new Error('Server error!');
        }

        const data = await response.json();

        return data?.data;
    } catch (error) {
        throw new Error('Error in fetching Flash Cards!');
    }
});
export const getAllSubjects = createAsyncThunk('/getAllSubjects', async () => {
    try {

        const cookies = document.cookie.split('; ');
        const tokenCookie = cookies.find((cookie) => cookie.startsWith('asvab-student-token='));
        const token = tokenCookie.split('=')[1];
        // if(!page){
        //     page=1
        // }
        // if (!limit) {
        //     limit = Number.MAX_SAFE_INTEGER
        // }
        // // // // console.log("subjectId, page, limit,", subjectId, page, limit)
        // + `?page=${page}&limit=${limit}`
        const response = await fetch(API_URL + 'getAllSubject', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        // // // console.log(response)
        if (!response.ok) {
            throw new Error('Server error!');
        }

        const data = await response.json();

        return data?.data;
    } catch (error) {
        throw new Error('Error in fetching Flash Cards!');
    }
});


const counterSlice = createSlice({
    name: 'counter',
    initialState: {
        FlashCards: [],
        subjects: []
    },
    reducers: {

        setFlashCardsEmpty(state) {
            state.FlashCards = []
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllFlashCards.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAllFlashCards.fulfilled, (state, action) => {
                // // // console.log('action.payload ==> ', action.payload.flashCards);
                state.status = 'succeeded';
                state.FlashCards = action.payload.flashCards;
            })
            .addCase(getAllFlashCards.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(getAllSubjects.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAllSubjects.fulfilled, (state, action) => {
                // // // console.log('action.payload getAllSubjects ==> ', action.payload);
                state.status = 'succeeded';
                state.subjects = action.payload;
            })
            .addCase(getAllSubjects.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
})

export const { increment, setFlashCardsEmpty } = counterSlice.actions
export default counterSlice.reducer