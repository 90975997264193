import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { API_URL } from '../../common/common';
import Swal from 'sweetalert2';
import { Box, Button, Card, CircularProgress, Container, Icon, IconButton, Typography } from '@mui/material';
import { styles } from './style';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// URL: http://localhost:3000/verifyEmail?token=loremipsumabcdxyz

const VerifyEmail = () => {
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true)
    const [responseMessege, setResponseMessege] = useState();
    const [responseStatus, setResponseStatus] = useState();
    const token = searchParams.get('token');
    const navigate = useNavigate()
    // // // console.log("token ==> ", token); // This will log the token in the console

    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            if (responseStatus) {
                navigate('/login');
            } else {
                navigate('/register');
            }
        }
    }, [countdown, navigate]);

    const verifyEmailFetch = async () => {

        const emailVerificationResponse = await fetch(API_URL + 'verifyEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
        });
        const emailVerificationResponseData = await emailVerificationResponse.json();
        // localStorage.setItem("studentId", data?.data?.user?._id)
        // // // console.log('emailVerificationResponseData ==> ', emailVerificationResponseData);
        setResponseMessege(emailVerificationResponseData.message)
        setResponseStatus(emailVerificationResponseData.status)
        setIsLoading(false)
        // if (emailVerificationResponseData.status) {
        //     navigate("/login")


        // }
        // else {


        // }

    }

    useEffect(() => {
        // Make API call (URL: /verifyEmail, Method: POST, Body: {token}).
        verifyEmailFetch()
    }, [])


    /* Make API call (URL: /verifyEmail, Method: POST, Body: {token}).
    Verify Email success response will be like this:
    {
    "code": 200,
    "status": true,
    "message": "Email verified successfully!",
    "data": {
        "_id": "1234loremipsumabcdxyz",
        "email": "loremipsum@gmail.com",
        "password": "encryptedpassword",
        "isAdmin": 0,
        "isEmailVerified": true,
        "__v": 0
        }
    }

    Error response will be like this:
    {
    "code": 401,
    "status": false,
    "message": "Invalid token!"
    }
    or like this:
    {
        "code": 401,
        "status": false,
        "message": "Token has expired!"
    }
    If status is true, show a message like "Email verified successfully! Please login to continue." and redirect to /login else show a message like "Invalid token!" or "Token has expired!" and redirect to /register.
    */


    return (
        <Box
            sx={styles.verifyEmailOuter}
        >
            <Container
                sx={styles.container}
            >
                {isLoading ?
                    <CircularProgress size={50} color='secondary' /> :
                    <Box
                        sx={styles.cardOuter}
                    >
                        {responseStatus ?
                            <Card sx={styles.card}>
                                <Typography variant="h2">{responseMessege}</Typography>
                                <CheckCircleIcon fontSize='inherit' style={{ fontSize: 50 }} color='success' />
                                <Typography variant="body1">Redirecting to login page in {countdown}...</Typography>
                                <Typography variant="body1">Or <Link to="/login">click here</Link> to login now.</Typography>
                            </Card> :
                            <Card sx={styles.card}>
                                <Typography variant="h2">{responseMessege}</Typography>
                                <ErrorIcon fontSize='inherit' style={{ fontSize: 50 }} color='error' />
                                <Typography variant="body1">Redirecting to register page in {countdown}...</Typography>
                                <Typography variant="body1">Or <Link to="/register">click here</Link> to register now.</Typography>
                            </Card>}
                    </Box>
                }
            </Container >
        </Box>
    )
}

export default VerifyEmail